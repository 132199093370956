import "./DataHomePage.scss";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import SplitPane from "react-split-pane";
import {
    INavLink,
    MessageBar,
    MessageBarType,
    Nav,
    SearchBox,
    StickyPositionType,
    Sticky,
    ScrollablePane,
    IconButton,
    IIconProps,
    TagPicker,
    ITag,
    Panel,
    PanelType,
    Stack,
} from "@fluentui/react";

import { Content } from "./Content";
import {
    queryDatasetCatalog,
    syncDatasetCatalog,
    IOcrDatasetCatalog,
    IOcrDatasetDisplayInfo,
    UnauthorizedError,
} from "./Request";
import { Consumer } from "../../Layout";
import { DatasetTag } from "../../DataContract";
import _ from "lodash";

const FILTER_TAGS = [DatasetTag.Category, DatasetTag.Modality, DatasetTag.Task];

interface DatasetInfoParams {
    name: string;
    version?: string;
}

export interface IHomePageState {
    datasetCatalog: IOcrDatasetCatalog;
    displayCatalog: IOcrDatasetDisplayInfo[];
    errorMessage: string;
    selectedNavKey: string;
    allTags: Map<string, string[]>;
    searchStr: string;
    selectedTags: Map<string, string[]>;
    isOpenFilterPanel: boolean;
}

export class DataHomePage extends React.Component<
    RouteComponentProps,
    IHomePageState
> {
    private _content: React.RefObject<Content>;
    private _timer?: NodeJS.Timeout;

    constructor(props: RouteComponentProps) {
        super(props);
        this._onChanged = this._onChanged.bind(this);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this._onNavLinkClick = this._onNavLinkClick.bind(this);
        this._refreshDatasetCatalog = this._refreshDatasetCatalog.bind(this);
        this._parseURLSearchParams = this._parseURLSearchParams.bind(this);

        this._content = React.createRef();

        const defaultCatalog: IOcrDatasetCatalog = {
            datasets: [],
            lastsync: "N/A",
        };

        this.state = {
            datasetCatalog: defaultCatalog,
            displayCatalog: [],
            errorMessage: "",
            selectedNavKey: "",
            allTags: new Map<string, string[]>(),
            searchStr: "",
            isOpenFilterPanel: false,
            selectedTags: new Map<string, string[]>(),
        };
    }
    public render() {
        const {
            allTags,
            displayCatalog,
            selectedNavKey,
            selectedTags,
            searchStr,
        } = this.state;

        const hasSelectedTags =
            selectedTags.size > 0 &&
            Array.from(selectedTags).some(([_key, vals]) => vals.length > 0);

        return (
            <>
                <Consumer>
                    {(value) => {
                        console.log(value);
                        return (
                            <>
                                <Panel
                                    headerText="Filter"
                                    isOpen={this.state.isOpenFilterPanel}
                                    type={PanelType.smallFixedNear}
                                    styles={{
                                        main: {
                                            width: 310,
                                            height: 350,
                                            marginLeft: 300,
                                            marginTop: 130,
                                        },
                                    }}
                                    closeButtonAriaLabel="Close"
                                    onDismiss={() =>
                                        this.setState({
                                            isOpenFilterPanel: false,
                                        })
                                    }
                                    isLightDismiss={true}
                                >
                                    {FILTER_TAGS.map((tagName) => {
                                        let selectedOptions: ITag[] = [];
                                        const selectedTagValues =
                                            selectedTags.get(tagName);
                                        if (
                                            selectedTagValues &&
                                            selectedTagValues.length > 0
                                        ) {
                                            selectedOptions =
                                                selectedTagValues.map(
                                                    (value) => {
                                                        return {
                                                            name: value,
                                                            key: value,
                                                        };
                                                    }
                                                );
                                        }

                                        const tagValues = allTags.get(tagName);

                                        return (
                                            <TagPicker
                                                key={`tagPicker_${tagName}`}
                                                styles={{
                                                    root: { marginBottom: 15 },
                                                }}
                                                inputProps={{
                                                    placeholder: tagName,
                                                }}
                                                selectedItems={selectedOptions}
                                                onResolveSuggestions={(
                                                    filterStr: string,
                                                    selectedItems?:
                                                        | ITag[]
                                                        | undefined
                                                ) => {
                                                    return this._onResolveSuggestions(
                                                        filterStr,
                                                        tagName,
                                                        tagValues,
                                                        selectedItems
                                                    );
                                                }}
                                                onEmptyResolveSuggestions={(
                                                    selectedItems?:
                                                        | ITag[]
                                                        | undefined
                                                ) => {
                                                    return this._onEmptyResolveSuggestions(
                                                        tagName,
                                                        tagValues,
                                                        selectedItems
                                                    );
                                                }}
                                                onChange={(items) => {
                                                    this._onTagPickerChanged(
                                                        tagName,
                                                        items
                                                    );
                                                }}
                                            />
                                        );
                                    })}
                                </Panel>
                                <SplitPane
                                    style={{ position: "relative" }}
                                    split="vertical"
                                    minSize={200}
                                    defaultSize={280}
                                    pane1Style={{
                                        overflowY: "auto",
                                        backgroundColor: value
                                            ? "black"
                                            : "white",
                                        color: value ? "white" : "black",
                                    }}
                                    pane2Style={{
                                        overflow: "auto",
                                        backgroundColor: value
                                            ? "black"
                                            : "white",
                                        color: value ? "white" : "black",
                                    }}
                                >
                                    <ScrollablePane>
                                        <Sticky
                                            stickyPosition={
                                                StickyPositionType.Header
                                            }
                                        >
                                            {this.getMessageBar()}
                                            <Stack
                                                horizontal
                                                horizontalAlign="space-between"
                                            >
                                                <SearchBox
                                                    styles={{
                                                        root: {
                                                            width: hasSelectedTags
                                                                ? "75%"
                                                                : "100%",
                                                        },
                                                    }}
                                                    placeholder="Search"
                                                    onChange={(
                                                        event?: React.ChangeEvent<HTMLInputElement>,
                                                        newValue?: string
                                                    ) => {
                                                        if (this._timer) {
                                                            clearTimeout(
                                                                this._timer
                                                            );
                                                        }
                                                        if (
                                                            newValue !==
                                                            undefined
                                                        ) {
                                                            this._timer =
                                                                setTimeout(
                                                                    () => {
                                                                        this._onChanged(
                                                                            newValue
                                                                        );
                                                                    },
                                                                    300
                                                                );
                                                        }
                                                    }}
                                                    underlined={true}
                                                />
                                                <Stack
                                                    horizontal
                                                    horizontalAlign="end"
                                                >
                                                    <IconButton
                                                        title="Filter Dataset"
                                                        iconProps={{
                                                            iconName:
                                                                "PageListFilter",
                                                        }}
                                                        onClick={() => {
                                                            this.setState({
                                                                isOpenFilterPanel:
                                                                    true,
                                                            });
                                                        }}
                                                    />
                                                    {hasSelectedTags && (
                                                        <IconButton
                                                            title="Clear Filter"
                                                            iconProps={{
                                                                iconName:
                                                                    "ClearFilter",
                                                            }}
                                                            onClick={() => {
                                                                const filterDatasets =
                                                                    this._filterDatasets(
                                                                        searchStr,
                                                                        "",
                                                                        [],
                                                                        true
                                                                    );

                                                                this.setState({
                                                                    selectedTags:
                                                                        new Map<
                                                                            string,
                                                                            string[]
                                                                        >(),
                                                                    displayCatalog:
                                                                        filterDatasets,
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                </Stack>
                                            </Stack>
                                        </Sticky>
                                        <Nav
                                            onLinkClick={this._onNavLinkClick}
                                            selectedKey={selectedNavKey}
                                            ariaLabel="Nav basic example"
                                            groups={[
                                                // TODO: temporarliy disable SQL table support
                                                // {
                                                //   name: 'SQL TABLES',
                                                //   links: [
                                                //     {
                                                //       name: 'RawImages',
                                                //       links: [],
                                                //       url: '',
                                                //       datasetName: 'sql_rawimages',
                                                //       datasetVersion: '',
                                                //       id: 'sql_rawimages',
                                                //       key: 'key-sql_rawimgaes'
                                                //     },
                                                //     {
                                                //       name: 'TextLines',
                                                //       links: [],
                                                //       url: '',
                                                //       datasetName: 'sql_textlines',
                                                //       datasetVersion: '',
                                                //       id: 'sql_textlines',
                                                //       key: 'key-sql_textlines'
                                                //     }
                                                //   ]
                                                // },
                                                {
                                                    name: "DATASETS",
                                                    links: displayCatalog.map(
                                                        (dataset, index) => {
                                                            const keyStr = `key-${dataset.name}|`;
                                                            return {
                                                                name: dataset.name,
                                                                url: "",
                                                                links: dataset.versions.map(
                                                                    (
                                                                        version: string
                                                                    ) => {
                                                                        return {
                                                                            name:
                                                                                "version " +
                                                                                version,
                                                                            url: "",
                                                                            key: `${keyStr}-${version}`,
                                                                            datasetName:
                                                                                dataset.name,
                                                                            datasetVersion:
                                                                                version,
                                                                            id:
                                                                                index +
                                                                                "-" +
                                                                                version,
                                                                        };
                                                                    }
                                                                ),
                                                                id: index.toString(),
                                                                key: keyStr,
                                                                datasetName:
                                                                    dataset.name,
                                                                datasetVersion:
                                                                    dataset.defaultVersion,
                                                                isExpanded:
                                                                    selectedNavKey.indexOf(
                                                                        keyStr
                                                                    ) > -1
                                                                        ? true
                                                                        : false,
                                                            };
                                                        }
                                                    ),
                                                },
                                            ]}
                                        />
                                    </ScrollablePane>
                                    <Content ref={this._content} />
                                </SplitPane>
                            </>
                        );
                    }}
                </Consumer>
            </>
        );
    }

    public componentDidMount(): void {
        queryDatasetCatalog()
            .then((response) => {
                let arr = [...response.datasets];
                const allTags = new Map<string, string[]>();

                FILTER_TAGS.forEach((tagName) => {
                    const tagValues = this._loadTagPropValues(tagName, arr);
                    allTags.set(tagName, tagValues);
                });

                this.setState({
                    datasetCatalog: response,
                    displayCatalog: arr,
                    allTags: allTags,
                });
                if (
                    this._content.current &&
                    this.props.location.search.substr(1)
                ) {
                    let params = this._parseURLSearchParams(
                        this.props.location.search.substr(1)
                    );
                    this._content.current.setDataset(
                        params.name,
                        params.version!
                    );
                    const keyStr = `key-${params.name}|${
                        params.version ? "-" + params.version : ""
                    }`;

                    this.setState({
                        selectedNavKey: keyStr,
                    });
                }
            })
            .catch((error) => {
                const defaultCatalog: IOcrDatasetCatalog = {
                    datasets: [],
                    lastsync: "N/A",
                };
                if (error instanceof UnauthorizedError) {
                    this.setState({
                        datasetCatalog: defaultCatalog,
                        errorMessage:
                            "Authentication token expired. Please refresh the page to login again.",
                    });
                }
            });
    }

    private _parseURLSearchParams(param: string) {
        const { displayCatalog } = this.state;
        let dataSetInfo = new URLSearchParams(param);
        let urlparams: DatasetInfoParams = { name: "" };
        if (dataSetInfo.has("name") && dataSetInfo.get("name")) {
            urlparams.name = dataSetInfo.get("name")!.toString();
        }
        let selectedDataset = displayCatalog.filter(
            (val) => val.name === urlparams.name
        );
        const version = dataSetInfo.get("version");
        if (version) {
            if (version === "latest") {
                urlparams.version = selectedDataset[0].defaultVersion;
            } else {
                urlparams.version = version;
            }
        } else {
            urlparams.version = selectedDataset[0].defaultVersion;
        }
        return urlparams;
    }

    private _onNavLinkClick(
        ev?: React.MouseEvent<HTMLElement>,
        item?: INavLink
    ) {
        const { displayCatalog } = this.state;
        if (item && item.id !== undefined && item.key !== undefined) {
            if (this._content.current) {
                let viewVersion = "";
                let selectedDataset = displayCatalog.filter(
                    (val) => val.name === item.datasetName
                );
                if (item.datasetVersion === selectedDataset[0].defaultVersion) {
                    viewVersion = "latest";
                } else {
                    viewVersion = item.datasetVersion;
                }

                let newUrl = `${window.location.origin}${window.location.pathname}?name=${item.datasetName}&version=${viewVersion}`;
                window.history.replaceState(
                    { page: 1 },
                    item.datasetName,
                    newUrl
                );
                this._content.current.setDataset(
                    item.datasetName,
                    item.datasetVersion
                );
                this.setState({ selectedNavKey: item.key });
            }
        }
    }

    private _onItemInvoked(item: string): void {
        console.log(`Item invoked: ${item}`);
    }

    private _onChanged(newValue: any) {
        const filterDatasets = this._filterDatasets(newValue, "", []);
        this.setState({
            searchStr: newValue,
            displayCatalog: filterDatasets,
        });
    }

    private _onResolveSuggestions(
        filterStr: string,
        tagName: string,
        tagValues?: string[],
        selectedItems?: ITag[]
    ): ITag[] {
        const { displayCatalog, selectedTags, searchStr } = this.state;

        let displayTagValues =
            searchStr || selectedTags.size > 0
                ? this._loadTagPropValues(tagName, displayCatalog)
                : tagValues
                ? _.cloneDeep(tagValues)
                : [];
        if (selectedItems && selectedItems.length > 0) {
            const selectedKeys = selectedItems.map((item) =>
                item.key.toString()
            );
            displayTagValues = displayTagValues.filter(
                (tagValue) => !selectedKeys.includes(tagValue)
            );
        }

        if (filterStr.length > 0) {
            displayTagValues = displayTagValues.filter((tagValue) =>
                tagValue
                    .toLocaleLowerCase()
                    .includes(filterStr.toLocaleLowerCase())
            );
        }

        return displayTagValues.map((tagValue) => {
            return {
                name: tagValue,
                key: tagValue,
            };
        });
    }

    private _onEmptyResolveSuggestions(
        tagName: string,
        tagValues?: string[],
        selectedItems?: ITag[]
    ) {
        const { displayCatalog, selectedTags, searchStr } = this.state;
        let displayTagValues =
            searchStr || selectedTags.size > 0
                ? this._loadTagPropValues(tagName, displayCatalog)
                : tagValues
                ? _.cloneDeep(tagValues)
                : [];
        if (selectedItems && selectedItems.length > 0) {
            const selectedKeys = selectedItems.map((item) =>
                item.key.toString()
            );

            displayTagValues = displayTagValues.filter(
                (tagValue) => !selectedKeys.includes(tagValue)
            );
        }

        if (displayTagValues.length === 0) {
            alert("No more options to select.");
        }
        return displayTagValues.map((tagValue) => {
            return {
                name: tagValue,
                key: tagValue,
            };
        });
    }

    private _onTagPickerChanged(tagName: string, items?: ITag[]) {
        if (items !== undefined) {
            const { selectedTags, searchStr } = this.state;
            if (items.length > 0) {
                const selectedItemKeys = items.map((item) =>
                    item.key.toString()
                );
                const filterDatasets = this._filterDatasets(
                    searchStr,
                    tagName,
                    selectedItemKeys
                );

                selectedTags.set(tagName, selectedItemKeys);
                this.setState({
                    selectedTags: selectedTags,
                    displayCatalog: filterDatasets,
                });
            } else if (searchStr) {
                const filterDatasets = this._filterDatasets(
                    searchStr,
                    tagName,
                    []
                );

                selectedTags.set(tagName, []);

                this.setState({
                    selectedTags: selectedTags,
                    displayCatalog: filterDatasets,
                });
            } else {
                const filterDatasets = this._filterDatasets("", tagName, []);
                selectedTags.set(tagName, []);
                this.setState({
                    selectedTags: selectedTags,
                    displayCatalog: filterDatasets,
                });
            }
        }
    }

    private _filterDatasets(
        searchStr: string,
        toFilterTagName: string,
        toSelectedTagValues: string[],
        isClearFilter: boolean = false
    ) {
        const { selectedTags, datasetCatalog } = this.state;

        let filterDatasets = datasetCatalog.datasets;
        if (searchStr) {
            filterDatasets = filterDatasets.filter(
                (item: any) =>
                    item.name.toLowerCase().indexOf(searchStr.toLowerCase()) !==
                    -1
            );
        }

        if (isClearFilter === false) {
            FILTER_TAGS.forEach((tagName) => {
                const selectedTagValues =
                    tagName === toFilterTagName
                        ? toSelectedTagValues
                        : selectedTags.get(tagName);
                if (selectedTagValues && selectedTagValues.length > 0) {
                    filterDatasets = filterDatasets
                        .map((dataset) => {
                            const filterVerTags = dataset.verTags.filter(
                                (verTag) => {
                                    if (
                                        Object.keys(verTag.tags).includes(
                                            tagName
                                        )
                                    ) {
                                        const val = verTag.tags[
                                            tagName
                                        ].toString() as string;
                                        if (
                                            val &&
                                            selectedTagValues.includes(val)
                                        ) {
                                            return true;
                                        }
                                    }
                                    return false;
                                }
                            );

                            if (filterVerTags.length > 0) {
                                return {
                                    ...dataset,
                                    versions: filterVerTags.map(
                                        (verTag) => verTag.ver
                                    ),
                                    verTags: filterVerTags,
                                };
                            }

                            return null;
                        })
                        .filter(
                            (dataset) => dataset
                        ) as IOcrDatasetDisplayInfo[];
                }
            });
        }

        return filterDatasets;
    }

    private _loadTagPropValues(
        tagName: string,
        ocrDatasetArr: IOcrDatasetDisplayInfo[]
    ) {
        const categorySet = new Set<string>();
        ocrDatasetArr.forEach((dataset) => {
            dataset.verTags.forEach((verTag) => {
                if (Object.keys(verTag.tags).includes(tagName)) {
                    const val = verTag.tags[tagName].toString() as string;
                    if (val && !categorySet.has(val)) {
                        categorySet.add(val);
                    }
                }
            });
        });

        return Array.from(categorySet);
    }

    private _prettyDateTime(date: string): string {
        // Get a datetime object or a int() Epoch timestamp and return a
        // pretty string like 'an hour ago', 'Yesterday', '3 months ago',
        // 'just now', etc

        if (!Date.parse(date)) {
            return "NaN";
        }
        const second_diff = (Date.now() - Date.parse(date)) / 1000;
        if (second_diff < 10) {
            return "just now";
        }
        if (second_diff < 60) {
            return Math.round(second_diff) + " seconds ago";
        }
        if (second_diff < 120) {
            return "a minute ago";
        }
        if (second_diff < 3600) {
            return Math.round(second_diff / 60) + " minutes ago";
        }
        if (second_diff < 7200) {
            return "an hour ago";
        }
        return Math.round(second_diff / 3600) + " hours ago";
    }

    private _refreshDatasetCatalog(): void {
        syncDatasetCatalog().then((res) => {
            if (res) {
                queryDatasetCatalog()
                    .then((response) => {
                        let arr = [...response.datasets];
                        const allTags = new Map<string, string[]>();
                        FILTER_TAGS.forEach((tagName) => {
                            const tagValues = this._loadTagPropValues(
                                tagName,
                                arr
                            );
                            allTags.set(tagName, tagValues);
                        });

                        this.setState({
                            datasetCatalog: response,
                            displayCatalog: arr,
                            allTags: allTags,
                        });
                    })
                    .catch((error) => {
                        const defaultCatalog: IOcrDatasetCatalog = {
                            datasets: [],
                            lastsync: "N/A",
                        };
                        if (error instanceof UnauthorizedError) {
                            this.setState({
                                datasetCatalog: defaultCatalog,
                                errorMessage:
                                    "Authentication token expired. Please refresh the page to login again.",
                            });
                        }
                    });
            }
        });
    }

    private getMessageBar() {
        const { errorMessage, datasetCatalog } = this.state;
        const refreshIcon: IIconProps = { iconName: "Refresh" };
        if (errorMessage) {
            const ErrorMessage = () => (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                    style={{ whiteSpace: "break-spaces" }}
                >
                    {errorMessage}
                </MessageBar>
            );
            return <div>{<ErrorMessage />}</div>;
        } else {
            return (
                <MessageBar messageBarType={MessageBarType.success}>
                    Last synced: {this._prettyDateTime(datasetCatalog.lastsync)}
                    .
                    <IconButton
                        iconProps={refreshIcon}
                        title="Refresh"
                        style={{
                            height: "16px",
                            position: "absolute",
                            right: "5px",
                        }}
                        ariaLabel="Refresh"
                        onClick={this._refreshDatasetCatalog}
                    />
                </MessageBar>
            );
        }
    }
}
