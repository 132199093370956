import * as React from "react";
import {
    Stack,
    Label,
    ComboBox,
    IComboBoxOption,
    IComboBoxStyles,
    IComboBox,
    CompoundButton,
    SelectableOptionMenuItemType,
    IconButton,
} from "@fluentui/react";
import {
    BenchmarkType,
    DatasetSet,
    ErrorRateType,
    IDataItem,
    Record,
    RecordDetail,
    RecordFactory,
    TestType,
    Workspaces,
} from "../../../DataContract";
import {
    colorByTheme,
    getColorByIndex4Trend,
    intersectionFromMultiArray,
} from "../../../Utils";
import {
    IGroupedLineChartData,
    ILineChartData,
    ILineChartDataPoint,
    LineChartWithXScaleTime,
} from "../../../Controls/D3/LineChartWithXScaleTime";
import _ from "lodash";
import { Consumer } from "../../../Layout";
import { ReleaseRecord } from "../../../DataContract/ReleaseRecord";
import {
    hideLoading,
    hidePrepareRenderDataLoading,
    showPrepareRenderDataLoading,
} from "../../../Utils/LoadingUtil";
const DATASETS_FILTER_KEY = "__Expansion__";
const DEFAULT_CATEGORY = "Overall Categories";
const POINT_NAME = "Error Rate";
const GA_TYPES: string[] = [BenchmarkType.GA30, BenchmarkType.GA31];
const IGNORED_DATE_LIMIT_TYPES = [...GA_TYPES, BenchmarkType.AWS];
const TEST_TYPES: string[] = [TestType.DevTesting, TestType.BlindTesting];
const TEST_TYPES_OPTIONS = TEST_TYPES.map((t) => {
    return {
        key: t,
        text: t,
    } as IComboBoxOption;
});

interface benchmarkEntity {
    color: string;
    records: Record[];
    dataItemsMap: Map<string, (IDataItem<any> | undefined)[]>;
}

const dateOptions: IComboBoxOption[] = [
    {
        key: 3,
        text: "3 months",
    },
    {
        key: 6,
        text: "6 months",
    },
    {
        key: 12,
        text: "12 months",
    },
];

const errorRateOptions: IComboBoxOption[] = [
    {
        key: ErrorRateType.WER as string,
        text: ErrorRateType.WER as string,
    },
    {
        key: ErrorRateType.EER as string,
        text: ErrorRateType.EER as string,
    },
];

const comboBoxStyles: Partial<IComboBoxStyles> = {
    root: { width: 130 },
    optionsContainer: { width: 130 },
};

interface IProps {
    panelIndex: number;
    hideCloseBtn?: boolean;
    closeHandler?: (panelIndex: number) => void;
}

interface IState {
    datasetOptions: IComboBoxOption[];
    categoryOptions: IComboBoxOption[];
    selectedBenchmarkEntitiyMap: Map<string, benchmarkEntity>;
    selectedRangeInMonth: number;
    selectedTestType: string;
    selectedDatasets: string[];
    selectedErrorRateType: string;
    selectedCategory?: string;
    groupedLineChartDataByDatasets?: IGroupedLineChartData[];
}

export class BenchmarkTrendPanel extends React.Component<IProps, IState> {
    private readonly benchmarkTypeMap = new Map<string, string>([
        ["Google", BenchmarkType.Google],
        ["GoogleDAI", BenchmarkType.GoogleDAI],
        ["AWS", BenchmarkType.AWS],
        ["GA 3.0", BenchmarkType.GA30],
        ["GA 3.1", BenchmarkType.GA31],
        ["Master", BenchmarkType.Master],
    ]);

    constructor(props: IProps) {
        super(props);

        this._onBenchmarkTypeCheckboxChange =
            this._onBenchmarkTypeCheckboxChange.bind(this);
        this._loadDatasets = this._loadDatasets.bind(this);
        this._loadMetrics = _.debounce(this._loadMetrics.bind(this), 1000);
        this._loadLineChart = this._loadLineChart.bind(this);

        this.state = {
            datasetOptions: [],
            categoryOptions: [],
            selectedDatasets: [],
            selectedRangeInMonth: 12,
            selectedBenchmarkEntitiyMap: new Map<string, benchmarkEntity>(),
            selectedErrorRateType: ErrorRateType.WER as string,
            selectedTestType: TEST_TYPES[0],
        };
    }
    public render() {
        const { closeHandler, hideCloseBtn, panelIndex } = this.props;
        const {
            selectedRangeInMonth,
            selectedErrorRateType,
            selectedTestType,
            selectedDatasets,
            datasetOptions,
            categoryOptions,
            selectedCategory,
            groupedLineChartDataByDatasets,
        } = this.state;

        const yAxisName = this._getYaxisName(selectedErrorRateType);

        let displayDatasetOptions = _.cloneDeep(datasetOptions);
        let displayGroupedLineChartData = groupedLineChartDataByDatasets;
        if (displayDatasetOptions && displayDatasetOptions.length > 0) {
            if (selectedDatasets.includes(DATASETS_FILTER_KEY)) {
                displayDatasetOptions = displayDatasetOptions.filter(
                    (datasetOption) => {
                        const keyStr = datasetOption.key.toString();
                        const includeSymbol = ["Unofficial", "Coverage"].some(
                            (str) => keyStr.includes(str)
                        );

                        const startsWithSymbol = [
                            "Entity_",
                            "single_char",
                            "Vertical_",
                        ].some((str) => keyStr.startsWith(str));

                        return !includeSymbol && !startsWithSymbol;
                    }
                );

                if (
                    groupedLineChartDataByDatasets &&
                    groupedLineChartDataByDatasets.length > 0
                ) {
                    displayGroupedLineChartData =
                        groupedLineChartDataByDatasets.filter((l) =>
                            displayDatasetOptions.some(
                                (o) => o.key.toString() === l.chartTitle
                            )
                        );
                }
            }

            const headerOptions: IComboBoxOption[] = [
                {
                    key: "Filter",
                    text: "Filter",
                    itemType: SelectableOptionMenuItemType.Header,
                },
                {
                    key: DATASETS_FILTER_KEY,
                    text: "Expansion / All",
                    itemType: SelectableOptionMenuItemType.Normal,
                },
                {
                    key: "_divider_",
                    text: "_divider_",
                    itemType: SelectableOptionMenuItemType.Divider,
                },
                {
                    key: "OptionHeader",
                    text: "OptionHeader",
                    itemType: SelectableOptionMenuItemType.Header,
                },
            ];

            displayDatasetOptions.unshift(...headerOptions);
        }

        return (
            <Consumer>
                {(isDarkTheme) => {
                    return (
                        <Stack verticalFill style={{ height: "auto" }}>
                            <Stack horizontal verticalAlign="center">
                                <Label className="pageLabel">
                                    Benchmark Trend
                                </Label>

                                <Stack
                                    horizontal
                                    verticalAlign="end"
                                    style={{
                                        marginLeft: "auto",
                                        alignItems: "center",
                                    }}
                                >
                                    {!!!hideCloseBtn && (
                                        <IconButton
                                            style={{
                                                width: 20,
                                                height: 20,
                                            }}
                                            iconProps={{
                                                iconName: "BoxMultiplySolid",
                                                styles: {
                                                    root: { fontSize: 20 },
                                                },
                                            }}
                                            onClick={() => {
                                                closeHandler &&
                                                    closeHandler(panelIndex);
                                            }}
                                        ></IconButton>
                                    )}
                                </Stack>
                            </Stack>

                            <Stack
                                horizontal
                                verticalAlign="end"
                                style={{ marginTop: "10px" }}
                                tokens={{ childrenGap: 20 }}
                            >
                                {Array.from(this.benchmarkTypeMap).map(
                                    ([typeName, typeKey], index) => {
                                        const colorStr =
                                            getColorByIndex4Trend(index);
                                        return (
                                            <Stack
                                                horizontal
                                                verticalAlign="start"
                                                key={`benchmarkType_${index}`}
                                            >
                                                <CompoundButton
                                                    checked={this.state.selectedBenchmarkEntitiyMap.has(
                                                        typeKey
                                                    )}
                                                    style={{
                                                        width: "226px",
                                                        height: "60px",
                                                        lineHeight: "40px",
                                                        borderLeft: ` ${
                                                            !this.state.selectedBenchmarkEntitiyMap.has(
                                                                typeKey
                                                            )
                                                                ? "16px solid rgb(185 183 180)"
                                                                : `16px solid ${colorStr}`
                                                        }`,
                                                        fontWeight: "bold",
                                                        backgroundColor: `${
                                                            isDarkTheme
                                                                ? "#000000"
                                                                : "#ffffff"
                                                        }`,
                                                        borderRadius:
                                                            "0px 15px 0px 15px",
                                                    }}
                                                    styles={{
                                                        rootHovered: {
                                                            borderColor:
                                                                colorStr,
                                                            borderWidth: "2px",
                                                        },
                                                    }}
                                                    onClick={(ev?: any) => {
                                                        let checked: any =
                                                            ev.target
                                                                .textContent;
                                                        this._onBenchmarkTypeCheckboxChange(
                                                            typeKey,
                                                            colorStr,
                                                            ev,
                                                            this.state.selectedBenchmarkEntitiyMap.has(
                                                                checked
                                                            )
                                                        );
                                                    }}
                                                >
                                                    <Stack
                                                        horizontal
                                                        verticalAlign="center"
                                                    >
                                                        <label
                                                            style={{
                                                                marginTop:
                                                                    "2px",
                                                                color: ` ${
                                                                    !this.state.selectedBenchmarkEntitiyMap.has(
                                                                        typeKey
                                                                    )
                                                                        ? `#b9b7b4`
                                                                        : colorByTheme(
                                                                              isDarkTheme
                                                                          )
                                                                }`,
                                                            }}
                                                        >
                                                            {typeName}
                                                        </label>
                                                    </Stack>
                                                </CompoundButton>
                                            </Stack>
                                        );
                                    }
                                )}
                            </Stack>

                            <Stack
                                horizontal
                                verticalAlign="end"
                                tokens={{ childrenGap: 20 }}
                                style={{ borderRadius: "16px" }}
                            >
                                <ComboBox
                                    label="Time Range"
                                    options={dateOptions}
                                    styles={comboBoxStyles}
                                    selectedKey={selectedRangeInMonth}
                                    onChange={(
                                        event: React.FormEvent<IComboBox>,
                                        option?: IComboBoxOption | undefined
                                    ) => {
                                        const { selectedRangeInMonth } =
                                            this.state;
                                        if (
                                            option &&
                                            option.key &&
                                            option.key !== selectedRangeInMonth
                                        ) {
                                            this._loadDatasets(
                                                undefined,
                                                option.key as number
                                            );
                                        }
                                    }}
                                />
                                <ComboBox
                                    label="Test Type"
                                    styles={comboBoxStyles}
                                    options={TEST_TYPES_OPTIONS}
                                    selectedKey={selectedTestType}
                                    onChange={(
                                        event: React.FormEvent<IComboBox>,
                                        option?: IComboBoxOption | undefined
                                    ) => {
                                        if (option) {
                                            const { selectedTestType } =
                                                this.state;
                                            if (
                                                selectedTestType !== option.key
                                            ) {
                                                this._loadDatasets(
                                                    option.key as string
                                                );
                                            }
                                        }
                                    }}
                                />

                                {datasetOptions.length > 0 && (
                                    <>
                                        <ComboBox
                                            selectedKey={selectedDatasets}
                                            label="Dataset"
                                            options={displayDatasetOptions}
                                            styles={{
                                                root: { width: 400 },
                                                optionsContainer: {
                                                    width: 400,
                                                    color: `${
                                                        isDarkTheme
                                                            ? "#ffffff"
                                                            : "#000000"
                                                    }`,
                                                },
                                            }}
                                            multiSelect
                                            onChange={(
                                                event: React.FormEvent<IComboBox>,
                                                option?:
                                                    | IComboBoxOption
                                                    | undefined
                                            ) => {
                                                let {
                                                    selectedDatasets,
                                                    selectedErrorRateType,
                                                } = this.state;
                                                if (option && option.key) {
                                                    const optionKey =
                                                        option.key as string;
                                                    if (
                                                        option.selected === true
                                                    ) {
                                                        if (
                                                            !selectedDatasets.includes(
                                                                optionKey
                                                            )
                                                        ) {
                                                            selectedDatasets.push(
                                                                optionKey
                                                            );
                                                        }
                                                    } else {
                                                        if (
                                                            selectedDatasets.includes(
                                                                optionKey
                                                            )
                                                        ) {
                                                            selectedDatasets =
                                                                selectedDatasets.filter(
                                                                    (dataset) =>
                                                                        dataset !==
                                                                        optionKey
                                                                );
                                                        }
                                                    }

                                                    if (
                                                        selectedDatasets.length ===
                                                        1
                                                    ) {
                                                        if (
                                                            selectedDatasets[0].startsWith(
                                                                "Entity_"
                                                            )
                                                        ) {
                                                            selectedErrorRateType =
                                                                ErrorRateType.EER;
                                                        } else {
                                                            selectedErrorRateType =
                                                                ErrorRateType.WER;
                                                        }
                                                    }

                                                    this.setState(
                                                        {
                                                            selectedDatasets:
                                                                selectedDatasets,
                                                            selectedErrorRateType:
                                                                selectedErrorRateType,
                                                        },

                                                        () => {
                                                            this._loadMetrics();
                                                        }
                                                    );
                                                }
                                            }}
                                        />
                                        <ComboBox
                                            selectedKey={selectedErrorRateType}
                                            label="Error Rate Type"
                                            options={errorRateOptions}
                                            styles={{
                                                root: { width: 230 },
                                                optionsContainer: {
                                                    width: 230,
                                                },
                                            }}
                                            onChange={(
                                                event: React.FormEvent<IComboBox>,
                                                option?:
                                                    | IComboBoxOption
                                                    | undefined
                                            ) => {
                                                if (option && option.key) {
                                                    this.setState(
                                                        {
                                                            selectedErrorRateType:
                                                                option.key as string,
                                                        },
                                                        this._loadMetrics
                                                    );
                                                }
                                            }}
                                        ></ComboBox>
                                        {categoryOptions.length > 0 && (
                                            <ComboBox
                                                selectedKey={selectedCategory}
                                                label="Category"
                                                options={categoryOptions}
                                                styles={{
                                                    root: { width: 230 },
                                                    optionsContainer: {
                                                        width: 230,
                                                    },
                                                }}
                                                onChange={(
                                                    event: React.FormEvent<IComboBox>,
                                                    option?:
                                                        | IComboBoxOption
                                                        | undefined
                                                ) => {
                                                    if (option && option.key) {
                                                        this.setState(
                                                            {
                                                                selectedCategory:
                                                                    option.key as string,
                                                            },
                                                            this._loadLineChart
                                                        );
                                                    }
                                                }}
                                            />
                                        )}
                                    </>
                                )}
                            </Stack>

                            <Stack style={{ minHeight: 636 }}>
                                {displayGroupedLineChartData &&
                                    displayGroupedLineChartData.map(
                                        (groupedLineChartData, index) => {
                                            return (
                                                <Stack
                                                    key={`stack_lineChart_${index}`}
                                                    verticalFill
                                                    style={{ height: "auto" }}
                                                >
                                                    <LineChartWithXScaleTime
                                                        id={`panel_${panelIndex}_vLineChart_${index}`}
                                                        data={
                                                            groupedLineChartData
                                                        }
                                                        isDarkTheme={
                                                            isDarkTheme
                                                        }
                                                        height={500}
                                                        yAxisName={yAxisName}
                                                    ></LineChartWithXScaleTime>
                                                </Stack>
                                            );
                                        }
                                    )}
                            </Stack>
                        </Stack>
                    );
                }}
            </Consumer>
        );
    }

    public componentDidMount() {
        Array.from(this.benchmarkTypeMap).forEach(([_, typeKey], index) => {
            const colorStr = getColorByIndex4Trend(index);
            this._onBenchmarkTypeCheckboxChange(
                typeKey,
                colorStr,
                undefined,
                true
            );
        });
    }

    private async _loadDatasets(testType?: string, rangeInMonth?: number) {
        showPrepareRenderDataLoading();
        const {
            selectedBenchmarkEntitiyMap,
            selectedRangeInMonth,
            selectedTestType,
        } = this.state;

        if (rangeInMonth === undefined) {
            rangeInMonth = selectedRangeInMonth;
        }

        if (testType === undefined) {
            testType = selectedTestType;
        }

        if (selectedBenchmarkEntitiyMap.size > 0) {
            Array.from(selectedBenchmarkEntitiyMap.keys()).forEach((key) => {
                const entity = selectedBenchmarkEntitiyMap.get(key);
                if (entity) {
                    entity.records = [];
                    entity.dataItemsMap.clear();
                    selectedBenchmarkEntitiyMap.set(key, entity);
                }
            });
        }

        this.setState({
            groupedLineChartDataByDatasets: undefined,
            categoryOptions: [],
            selectedCategory: undefined,
            selectedRangeInMonth: rangeInMonth,
            selectedTestType: testType,
            selectedBenchmarkEntitiyMap: selectedBenchmarkEntitiyMap,
        });

        if (selectedBenchmarkEntitiyMap.size > 0) {
            const selectedBenchmarkKeys = Array.from(
                selectedBenchmarkEntitiyMap.keys()
            );

            const date = new Date();
            date.setMonth(date.getMonth() - rangeInMonth);
            const prevStamp = date.getTime();

            const queryTestType = testType ?? selectedTestType;
            const typeRecordsPromises = selectedBenchmarkKeys.map((key) => {
                const searchs =
                    `tag=${key}&testType=${queryTestType}` +
                    (IGNORED_DATE_LIMIT_TYPES.includes(key)
                        ? ""
                        : `&timeStamp=${prevStamp}`);
                return GA_TYPES.includes(key) &&
                    queryTestType === TestType.DevTesting
                    ? RecordFactory.fetchAll(
                          Workspaces.ReleaseTest,
                          1,
                          searchs,
                          100,
                          null,
                          false
                      ).then((records) => {
                          return (records as ReleaseRecord[]).map(
                              (r) => r.layoutAccuracyRecord
                          );
                      })
                    : RecordFactory.fetchAll(
                          Workspaces.Ocr,
                          1,
                          searchs,
                          100,
                          null,
                          false
                      );
            });

            const typeRecordsArr = await Promise.all(typeRecordsPromises);
            selectedBenchmarkKeys.forEach((key, index) => {
                const typeRecords = typeRecordsArr[index];
                if (typeRecords && typeRecords.length > 0) {
                    const filteredRecords = typeRecords.filter(
                        (r) => r.testType === testType
                    );

                    const entity = selectedBenchmarkEntitiyMap.get(key);
                    if (entity) {
                        entity.records = filteredRecords;
                        entity.dataItemsMap = new Map<
                            string,
                            (IDataItem<any> | undefined)[]
                        >();
                        selectedBenchmarkEntitiyMap.set(key, entity);
                    }
                }
            });

            const datasetArrayByTypesPromises = Array.from(
                selectedBenchmarkEntitiyMap
            ).map(async ([_, entity]) => {
                const datasetSet = new Set<string>();
                for (const r of entity.records) {
                    await r.initDetails();
                    r.getDetails().forEach((d) => {
                        if (!datasetSet.has(d.dataset.displayName)) {
                            datasetSet.add(d.dataset.displayName);
                        }
                    });
                }

                return Array.from(datasetSet);
            });

            Promise.all(datasetArrayByTypesPromises).then(
                (datasetArrayByTypes) => {
                    const commonDatasetArray: string[] =
                        datasetArrayByTypes && datasetArrayByTypes.length > 0
                            ? intersectionFromMultiArray(
                                  datasetArrayByTypes.map(
                                      (datasetArray) => datasetArray
                                  )
                              )
                            : [];

                    if (commonDatasetArray && commonDatasetArray.length > 0) {
                        const datasetOptions = commonDatasetArray
                            .sort(function (a, b) {
                                return a
                                    .toLowerCase()
                                    .localeCompare(b.toLowerCase());
                            })
                            .map((lang) => {
                                return {
                                    key: lang,
                                    text: lang,
                                    itemType:
                                        SelectableOptionMenuItemType.Normal,
                                } as IComboBoxOption;
                            });
                        const selectDatasets = [commonDatasetArray[0]];

                        this.setState(
                            {
                                selectedBenchmarkEntitiyMap:
                                    selectedBenchmarkEntitiyMap,
                                datasetOptions: datasetOptions,
                                selectedDatasets: selectDatasets,
                                categoryOptions: [],
                                selectedCategory: undefined,
                            },
                            this._loadMetrics
                        );
                    } else {
                        hidePrepareRenderDataLoading();
                        this.setState({
                            selectedBenchmarkEntitiyMap:
                                selectedBenchmarkEntitiyMap,
                            datasetOptions: [],
                            selectedDatasets: [],
                            categoryOptions: [],
                            selectedCategory: undefined,
                        });
                    }
                }
            );
        } else {
            hidePrepareRenderDataLoading();
            this.setState({
                selectedBenchmarkEntitiyMap: selectedBenchmarkEntitiyMap,
                datasetOptions: [],
                selectedDatasets: [],
                categoryOptions: [],
                selectedCategory: undefined,
            });
        }
    }

    private _loadMetrics() {
        const {
            selectedBenchmarkEntitiyMap,
            selectedErrorRateType,
            selectedDatasets,
        } = this.state;
        if (selectedDatasets && selectedDatasets.length > 0) {
            const [dataKey, categoryName] = this._getKeysByErrorRateType(
                selectedErrorRateType as string
            );
            const filteredDatasets = selectedDatasets.filter(
                (dataset) => dataset !== DATASETS_FILTER_KEY
            );

            const categoriesByDatasetPromises = filteredDatasets.map(
                async (selectedDataset) => {
                    const dataItemsArrayByTypesPromises = Array.from(
                        selectedBenchmarkEntitiyMap
                    ).map(async ([typeName, entity]) => {
                        const dataItems = await this._queryMetricsResult(
                            entity.records,
                            selectedDataset
                        );

                        return [typeName, dataItems] as [
                            string,
                            (IDataItem<any> | undefined)[]
                        ];
                    });

                    const dataItemsArrayByTypes = await Promise.all(
                        dataItemsArrayByTypesPromises
                    );

                    const categoriesArray = dataItemsArrayByTypes.map(
                        ([typeName, dataItems]) => {
                            const categorySet = new Set<string>();
                            dataItems.forEach((dataItem) => {
                                if (
                                    dataItem &&
                                    dataItem.metrics &&
                                    dataItem.metrics[dataKey] &&
                                    dataItem.metrics[dataKey].length > 0
                                ) {
                                    dataItem.metrics[dataKey].forEach(
                                        (o: any) => {
                                            if (
                                                !categorySet.has(
                                                    o[categoryName]
                                                )
                                            ) {
                                                categorySet.add(
                                                    o[categoryName]
                                                );
                                            }
                                        }
                                    );
                                }
                            });

                            const entity =
                                selectedBenchmarkEntitiyMap.get(typeName);
                            if (entity) {
                                entity.dataItemsMap.set(
                                    selectedDataset,
                                    dataItems
                                );

                                selectedBenchmarkEntitiyMap.set(
                                    typeName,
                                    entity
                                );
                            }

                            return Array.from(categorySet);
                        }
                    );

                    const commonCategoriesInBenchmarkTypes =
                        categoriesArray && categoriesArray.length > 0
                            ? intersectionFromMultiArray(categoriesArray).sort()
                            : [];

                    return commonCategoriesInBenchmarkTypes;
                }
            );

            Promise.all(categoriesByDatasetPromises).then(
                (categoriesByDataset) => {
                    let commonCategories =
                        categoriesByDataset && categoriesByDataset.length > 0
                            ? intersectionFromMultiArray(
                                  categoriesByDataset
                              ).sort()
                            : [];

                    if (commonCategories && commonCategories.length > 0) {
                        if (commonCategories.includes(DEFAULT_CATEGORY)) {
                            commonCategories = commonCategories.filter(
                                (c) => c !== DEFAULT_CATEGORY
                            );
                            commonCategories.unshift(DEFAULT_CATEGORY);
                        }
                        const categoryOptions = commonCategories.map((c) => {
                            return {
                                key: c,
                                text: c,
                            } as IComboBoxOption;
                        });
                        this.setState(
                            {
                                selectedBenchmarkEntitiyMap:
                                    selectedBenchmarkEntitiyMap,
                                categoryOptions: categoryOptions,
                                selectedCategory: commonCategories[0],
                            },
                            this._loadLineChart
                        );
                    } else {
                        hidePrepareRenderDataLoading();
                        this.setState(
                            {
                                selectedBenchmarkEntitiyMap:
                                    selectedBenchmarkEntitiyMap,
                                categoryOptions: [],
                                selectedCategory: undefined,
                                groupedLineChartDataByDatasets: undefined,
                            },
                            () => {
                                filteredDatasets.length > 0 &&
                                    setTimeout(() => {
                                        alert(
                                            "No common intersection in category"
                                        );
                                    }, 500);
                            }
                        );
                    }
                }
            );
        } else {
            this.setState(
                {
                    categoryOptions: [],
                    selectedCategory: undefined,
                },
                this._loadLineChart
            );
        }
    }

    private _loadLineChart() {
        const {
            selectedBenchmarkEntitiyMap,
            selectedCategory,
            selectedDatasets,
            selectedErrorRateType,
            selectedTestType,
        } = this.state;
        if (selectedCategory) {
            const [dataKey, categoryName] = this._getKeysByErrorRateType(
                selectedErrorRateType as string
            );

            const displayDatasets = selectedDatasets
                .filter((dataset) => dataset !== DATASETS_FILTER_KEY)
                .sort(function (a, b) {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                });

            const groupedLineChartDataByDatasets = displayDatasets.map(
                (dataset) => {
                    const lineChartDataItemArr = Array.from(
                        selectedBenchmarkEntitiyMap
                    ).map(([typeName, entity]) => {
                        const targetWorkspace =
                            GA_TYPES.includes(typeName) &&
                            selectedTestType === TestType.DevTesting
                                ? Workspaces.ReleaseTest
                                : Workspaces.Ocr;

                        const dataItems = entity.dataItemsMap.get(dataset);
                        const lineChartDataPoints =
                            dataItems && dataItems.length > 0
                                ? dataItems.map((dataItem) => {
                                      if (
                                          dataItem &&
                                          dataItem.metrics &&
                                          dataItem.metrics[dataKey] &&
                                          dataItem.metrics[dataKey].length > 0
                                      ) {
                                          const item = dataItem.metrics[
                                              dataKey
                                          ].find(
                                              (o: any) =>
                                                  o[categoryName] ===
                                                  selectedCategory
                                          );

                                          if (item) {
                                              const recordId =
                                                  dataItem.recordDetail.getRecord()
                                                      .id;
                                              return {
                                                  x: new Date(
                                                      entity.records[
                                                          dataItem.recordIndex
                                                      ].createTimestamp
                                                  ),
                                                  y: item.error,
                                                  name: POINT_NAME,
                                                  recordId: recordId,
                                                  datasetVer:
                                                      dataItem.recordDetail
                                                          .dataset.version,
                                                  recordPath: `/eval/${targetWorkspace}/id/${recordId}`,
                                              } as ILineChartDataPoint;
                                          }
                                      }

                                      return undefined;
                                  })
                                : [];

                        const lineChartDataPointArr =
                            lineChartDataPoints.filter(
                                (p) => p !== undefined
                            ) as ILineChartDataPoint[];

                        return {
                            legend: typeName,
                            color: entity.color,
                            data: lineChartDataPointArr.sort(
                                (a, b) => a.x.getTime() - b.x.getTime()
                            ),
                        } as ILineChartData;
                    });

                    this._optimizeIgnoredDateLimitLineChartData(
                        lineChartDataItemArr
                    );

                    const groupedLineChartData = {
                        chartTitle: dataset,
                        lineChartData: lineChartDataItemArr,
                    };

                    return groupedLineChartData;
                }
            );

            this.setState({
                groupedLineChartDataByDatasets: groupedLineChartDataByDatasets,
            });
        }
        hidePrepareRenderDataLoading();
    }

    private _onBenchmarkTypeCheckboxChange(
        typeKey: string,
        colorStr: string,
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        isChecked?: boolean
    ) {
        let { selectedBenchmarkEntitiyMap } = this.state;

        if (!!isChecked) {
            if (!selectedBenchmarkEntitiyMap.has(typeKey)) {
                selectedBenchmarkEntitiyMap.set(typeKey, {
                    color: colorStr,
                    records: [],
                    dataItemsMap: new Map<
                        string,
                        (IDataItem<any> | undefined)[]
                    >(),
                });
            } else {
                selectedBenchmarkEntitiyMap.delete(typeKey);
            }
        } else {
            if (selectedBenchmarkEntitiyMap.has(typeKey)) {
                selectedBenchmarkEntitiyMap.delete(typeKey);
            } else {
                if (!selectedBenchmarkEntitiyMap.has(typeKey)) {
                    selectedBenchmarkEntitiyMap.set(typeKey, {
                        color: colorStr,
                        records: [],
                        dataItemsMap: new Map<
                            string,
                            (IDataItem<any> | undefined)[]
                        >(),
                    });
                }
            }
        }

        this.setState(
            { selectedBenchmarkEntitiyMap: selectedBenchmarkEntitiyMap },
            this._loadDatasets
        );
    }

    private async _queryMetricsResult(
        records: Record[],
        selectLanguage: string
    ) {
        const { selectedErrorRateType } = this.state;

        const datasets = Array.from(
            new DatasetSet(records.flatMap((r) => r.getDatasets()))
        );

        const detailsList = records.map((r) => {
            if (!selectLanguage) {
                return r
                    .getDetails()
                    .filter((d) => d !== undefined) as RecordDetail[];
            } else {
                const recordDetails = datasets
                    .map((d) =>
                        r
                            .getDetails()
                            .find((rd) =>
                                selectLanguage
                                    ? rd.dataset.equals(d) &&
                                      rd.dataset.displayName === selectLanguage
                                    : rd.dataset.equals(d)
                            )
                    )
                    .filter((d) => d !== undefined) as RecordDetail[];

                return recordDetails.length > 0
                    ? [
                          recordDetails.sort(
                              (a, b) =>
                                  parseInt(b.dataset.version) -
                                  parseInt(a.dataset.version)
                          )[0],
                      ]
                    : recordDetails;
            }
        });

        const dataItems = await Promise.all(
            detailsList.flatMap((details, index) => {
                return details.map((detail) =>
                    detail
                        .fetchMetricsWithRecordDetail<any>(
                            selectedErrorRateType === ErrorRateType.WER
                                ? "basic_metrics.json"
                                : "doc_entities_wer_cer.json"
                        )
                        .then((metrics) => {
                            return {
                                recordIndex: index,
                                recordDetail: metrics[0],
                                metrics: metrics[1],
                            } as IDataItem<any>;
                        })
                        .catch((_error) => {
                            return undefined;
                        })
                );
            })
        );

        return dataItems;
    }

    private _getKeysByErrorRateType(selectedErrorRateType: string) {
        return selectedErrorRateType === ErrorRateType.WER
            ? ["category_wer", "category"]
            : ["entity_wer", "entity"];
    }

    private _getYaxisName(type: string) {
        return `${
            type === ErrorRateType.WER ? "Word" : "Entity"
        } Error Rate(%)`;
    }

    private _optimizeIgnoredDateLimitLineChartData(
        lineChartDataItemArr: ILineChartData[]
    ) {
        let experimentalLineChartDataItemArr = lineChartDataItemArr.filter(
            (lineChartDataItem) =>
                !IGNORED_DATE_LIMIT_TYPES.includes(lineChartDataItem.legend)
        );

        if (
            !experimentalLineChartDataItemArr ||
            experimentalLineChartDataItemArr.length === 0
        ) {
            experimentalLineChartDataItemArr = lineChartDataItemArr.filter(
                (lineChartDataItem) =>
                    IGNORED_DATE_LIMIT_TYPES.includes(lineChartDataItem.legend)
            );
        }

        if (
            experimentalLineChartDataItemArr &&
            experimentalLineChartDataItemArr.length > 0
        ) {
            if (
                lineChartDataItemArr.length === 1 &&
                IGNORED_DATE_LIMIT_TYPES.includes(
                    lineChartDataItemArr[0].legend
                )
            ) {
                const defaultPoint = _.cloneDeep(
                    lineChartDataItemArr[0].data[
                        lineChartDataItemArr[0].data.length - 1
                    ]
                );

                lineChartDataItemArr[0].data.push({
                    x: new Date(Date.now()),
                    y: defaultPoint.y,
                    name: POINT_NAME,
                    recordId: defaultPoint.recordId,
                    datasetVer: defaultPoint.datasetVer,
                    recordPath: defaultPoint.recordPath,
                });
            } else {
                let minDate: Date | undefined = undefined;
                let maxDate: Date | undefined = undefined;
                experimentalLineChartDataItemArr.forEach(
                    (lineChartDataItem) => {
                        if (lineChartDataItem.data.length > 0) {
                            if (
                                minDate === undefined ||
                                lineChartDataItem.data[0].x.getTime() <
                                    minDate.getTime()
                            ) {
                                minDate = lineChartDataItem.data[0].x;
                            }

                            if (
                                maxDate === undefined ||
                                maxDate.getTime() <
                                    lineChartDataItem.data[
                                        lineChartDataItem.data.length - 1
                                    ].x.getTime()
                            ) {
                                maxDate =
                                    lineChartDataItem.data[
                                        lineChartDataItem.data.length - 1
                                    ].x;
                            }
                        }
                    }
                );

                for (let i = 0; i < lineChartDataItemArr.length; i++) {
                    if (
                        IGNORED_DATE_LIMIT_TYPES.includes(
                            lineChartDataItemArr[i].legend
                        )
                    ) {
                        const defaultPoint = _.cloneDeep(
                            lineChartDataItemArr[i].data[
                                lineChartDataItemArr[i].data.length - 1
                            ]
                        );

                        if (minDate !== undefined) {
                            lineChartDataItemArr[i].data = [
                                {
                                    x: minDate,
                                    y: defaultPoint.y,
                                    name: POINT_NAME,
                                    recordId: defaultPoint.recordId,
                                    datasetVer: defaultPoint.datasetVer,
                                    recordPath: defaultPoint.recordPath,
                                },
                            ];
                        }

                        if (maxDate !== undefined) {
                            lineChartDataItemArr[i].data.push({
                                x: maxDate,
                                y: defaultPoint.y,
                                name: POINT_NAME,
                                recordId: defaultPoint.recordId,
                                datasetVer: defaultPoint.datasetVer,
                                recordPath: defaultPoint.recordPath,
                            });
                        }
                    }
                }
            }
        }
    }

    public componentWillUnmount() {
        hideLoading();
        hidePrepareRenderDataLoading();
        this.setState = () => {
            return;
        };
    }
}
