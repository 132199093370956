export const URL_PARAM_NAME_REPORTONLY = "reportonly";
export const URL_PARAM_NAME_SEARCH = "search";
export const URL_PARAM_NAME_TABKEY = "tabKey";
export const URL_PARAM_NAME_VIEWTYPE = "viewType";
export const URL_PARAM_NAME_IDENTIFY_TYPE = "identifyType";
export const URL_PARAM_NAME_IDENTIFY_INDEX = "identifyIndex";
export const URL_PARAM_NAME_OCR_RESULT_URL = "ocrResultUrl";

export const PORTAL_VER = "portal_ver";
export const STORAGE_VER = "storage_ver";

export const HIGHLIGHT_LOADING_ERROR = "highlightLoadingError";

export enum StringCheckMode {
    Includes,
    StartsWith,
    EndsWith,
}

export enum BenchmarkType {
    Google = "BMT_Google_Text",
    GoogleDAI = "BMT_GoogleDAI",
    AWS = "BMT_AWS",
    GA30 = "BMT_GA30",
    GA31 = "BMT_GA31",
    Master = "BMT_Master",
}

export enum TestType {
    DevTesting = "DevTesting",
    BlindTesting = "BlindTesting",
}

export enum IdentifyType {
    RecognizeRecog = "RecognizeRecog",
    BaselineRecog = "BaselineRecog",
    ConsistencyRecog = "ConsistencyRecog",
    BaselineBase = "BaselineBase",
}

export enum ErrorRateType {
    WER = "WER",
    EER = "EER",
}

export enum HttpStatusCode {
    CONTINUE = 100,
    SWITCHING_PROTOCOLS = 101,
    PROCESSING = 102,
    OK = 200,
    CREATED = 201,
    ACCEPTED = 202,
    NON_AUTHORITATIVE_INFORMATION = 203,
    NO_CONTENT = 204,
    RESET_CONTENT = 205,
    PARTIAL_CONTENT = 206,
    MULTI_STATUS = 207,
    ALREADY_REPORTED = 208,
    IM_USED = 226,
    MULTIPLE_CHOICES = 300,
    MOVED_PERMANENTLY = 301,
    FOUND = 302,
    SEE_OTHER = 303,
    NOT_MODIFIED = 304,
    USE_PROXY = 305,
    SWITCH_PROXY = 306,
    TEMPORARY_REDIRECT = 307,
    PERMANENT_REDIRECT = 308,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    PAYMENT_REQUIRED = 402,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    METHOD_NOT_ALLOWED = 405,
    NOT_ACCEPTABLE = 406,
    PROXY_AUTHENTICATION_REQUIRED = 407,
    REQUEST_TIMEOUT = 408,
    CONFLICT = 409,
    GONE = 410,
    LENGTH_REQUIRED = 411,
    PRECONDITION_FAILED = 412,
    PAYLOAD_TOO_LARGE = 413,
    URI_TOO_LONG = 414,
    UNSUPPORTED_MEDIA_TYPE = 415,
    RANGE_NOT_SATISFIABLE = 416,
    EXPECTATION_FAILED = 417,
    I_AM_A_TEAPOT = 418,
    MISDIRECTED_REQUEST = 421,
    UNPROCESSABLE_ENTITY = 422,
    LOCKED = 423,
    FAILED_DEPENDENCY = 424,
    UPGRADE_REQUIRED = 426,
    PRECONDITION_REQUIRED = 428,
    TOO_MANY_REQUESTS = 429,
    REQUEST_HEADER_FIELDS_TOO_LARGE = 431,
    UNAVAILABLE_FOR_LEGAL_REASONS = 451,
    INTERNAL_SERVER_ERROR = 500,
    NOT_IMPLEMENTED = 501,
    BAD_GATEWAY = 502,
    SERVICE_UNAVAILABLE = 503,
    GATEWAY_TIMEOUT = 504,
    HTTP_VERSION_NOT_SUPPORTED = 505,
    VARIANT_ALSO_NEGOTIATES = 506,
    INSUFFICIENT_STORAGE = 507,
    LOOP_DETECTED = 508,
    NOT_EXTENDED = 510,
    NETWORK_AUTHENTICATION_REQUIRED = 511,
}

export class Workspaces {
    public static readonly Ocr = "ocr";
    public static readonly OcrTable = "ocrtable";
    public static readonly OcrCheckbox = "ocrcheckbox";
    public static readonly OcrBackend = "ocrbackend";
    public static readonly Invoice = "invoice";
    public static readonly Kvp = "kvp";
    public static readonly CustomForm = "customform";
    public static readonly ReleaseTest = "releasetest";
    public static readonly CustomDocReleaseTest = "customdocreleasetest";
    public static readonly Receipt = "receipt";
    public static readonly BusinessCard = "businesscard";
    public static readonly IdCard = "idcard";
    public static readonly IndustryPrebuilt = "industryprebuilt";
    public static readonly HealthDoc = "healthdoc";
    public static readonly ExperimentalPrebuilt = "experimentalprebuilt";
    public static readonly VerticalTelemetry = "verticaltlm";
    public static readonly OcrPod = "ocrpod";
    public static readonly OcrMath = "ocrmath";
    public static readonly OcrBarcode = "ocrbarcode";
    public static readonly DocClassifier = "docclassifier";
    public static readonly OcrFigure = "ocrfigure";
    public static readonly QueryField = "queryfield";
}

export class WorkspaceGroups {
    public static readonly Taipei = [
        Workspaces.Receipt,
        Workspaces.BusinessCard,
        Workspaces.IdCard,
        Workspaces.IndustryPrebuilt,
        Workspaces.HealthDoc,
        Workspaces.ExperimentalPrebuilt,
    ];

    public static readonly ReleaseTests = [
        Workspaces.ReleaseTest,
        Workspaces.CustomDocReleaseTest,
    ];
}

export class EvalInfoKeys {
    public static readonly ModelInfo = "Model Info";
    public static readonly RuntimeVersion = "Runtime Version";
    public static readonly BuildSource = "Build Source";
    public static readonly TestType = "Test Type";
    public static readonly Algorithm = "Algorithm";
    public static readonly ExpLink = "Exp Link";
    public static readonly Tag = "Tag";

    public static readonly Id = "Id";
    public static readonly DatasetName = "Dataset Name";
    public static readonly DatasetType = "Dataset Type";
    public static readonly TimeRange = "Time Range";
    public static readonly BlobPath = "Blob Path";
    public static readonly Creator = "Creator";
    public static readonly CreateTime = "Create Time";
    public static readonly StepRunId = "Step Run Id";
}

export class Typename {
    public static readonly DetailView = "DetaiMetric";
    public static readonly CustFormOverview = "OverviewMetrics";
    public static readonly CustFormOverviewTableMetrics =
        "OverviewTableMetrics";
    public static readonly CustFormTableDetailMetrics = "TableDetailMetrics";
    public static readonly InvoiceImageView = "ByFieldMetric";
    public static readonly InvoiceOverview = "OverviewMetrics";
    public static readonly KvpDocMetricsView = "ByDocumentMetrics";
    public static readonly KvpOverview = "OverviewMetrics";
    public static readonly KvpTextlineView = "ByLineMetrics";
    public static readonly GeneralMetrics = "GeneralMetrics";
    public static readonly WordAlignMetrics = "WordAlignMetrics";
    public static readonly LargeModelMetrics = "LargeModelMetrics";
    public static readonly CharMetrics = "LanguageMetrics";
    public static readonly DetectionMetrics = "DetectionMetrics";
    public static readonly EntityMetrics = "EntityMetrics";
    public static readonly LatencyMetrics = "LatencyMetrics";
    public static readonly CheckboxGeneralMetrics = "GeneralMetrics";
    public static readonly VerticalErrorView = "ByFieldMetric";
    public static readonly VerticalOverview = "OverviewMetrics";
    public static readonly HighlightMetrics = "HighlightMetrics";
    public static readonly PerformanceMetrics = "PerformanceMetrics";
    public static readonly MATHSYMBOLMetrics = "SymbolMetrics";
    public static readonly OverviewMetrics = "OverviewMetrics";
    public static readonly OcrFigureImageView = "OcrFigureImageView";
}

export class FileExt {
    public static readonly Json = "json";
    public static readonly Html = "html";
}

export class Sortability {
    public static readonly MAX_SAFE_VALUE = Number.MAX_SAFE_INTEGER / 2;
    public static readonly MIN_SAFE_VALUE = Number.MIN_SAFE_INTEGER / 2;
}

export class RGB_Color {
    public static readonly Default = "#000000";
    public static readonly Green = "#008000";
    public static readonly Red = "#FF0000";
}

export class DatasetTag {
    public static readonly Category = "category";
    public static readonly Modality = "modality";
    public static readonly Task = "task";
}

export const PAGE_SIZE = 15;

export const FAVOURITE = "favouriteItems";

export const CANNOTCOMPARE =
    "Cannot compare due to the records data format not match!";

export const COLUMN_KEY_SEPARATOR = "~@K@E@Y~";

export class WorkspaceGroupsNav {
    public static readonly "Content Extraction" = [
        Workspaces.Ocr,
        Workspaces.OcrCheckbox,
        Workspaces.OcrMath,
        Workspaces.OcrBarcode,
    ];

    public static readonly "Layout Analysis" = [
        Workspaces.OcrTable,
        Workspaces.OcrPod,
        Workspaces.OcrFigure,
    ];

    public static readonly "KVP" = [Workspaces.Kvp];

    public static readonly "Vertical" = [
        Workspaces.Invoice,
        Workspaces.CustomForm,
        Workspaces.QueryField,
        Workspaces.DocClassifier,
        Workspaces.Receipt,
        Workspaces.BusinessCard,
        Workspaces.IdCard,
        Workspaces.IndustryPrebuilt,
        Workspaces.HealthDoc,
        Workspaces.ExperimentalPrebuilt,
        Workspaces.VerticalTelemetry,
    ];

    public static readonly "ReleaseTest" = [
        Workspaces.ReleaseTest,
        Workspaces.CustomDocReleaseTest,
    ];
}
