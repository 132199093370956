import { ILineChartDataPoint } from "@uifabric/charting";
import _, { Dictionary, round } from "lodash";
import { RecordDetail } from "../../DataContract";
import { ICommonState } from "../Common/CommonMetrics";
import { TableColumn } from "../../Controls";
import { capitalizeFirstLetter } from "../../Utils";

export interface OcrLatencyChartData {
    chartname: string;
    chartYLabel: string;
    chartXLabel: string;
    chartYMaxAxis: number;
    chartPoints: Array<ILineChartDataPoint>;
}

export interface OcrLatencyScatterData {
    chartAxisList: Array<string>;
    chartPoints: Array<ILineChartDataPoint>;
}

export interface OcrLatencyPerfMetrics {
    category_latency: Array<Dictionary<any>>;
    percentile_latency: Array<OcrLatencyChartData>;
}

export interface ILatencyDataItem {
    recordIndex: number;
    recordDetail: RecordDetail;
    metrics: OcrLatencyPerfMetrics;
}

export interface ILatencyDataItem {
    data?: ILatencyDataItem;
    latency: Dictionary<any>;
}

export interface IChartDataItem {
    data?: ILatencyDataItem;
    chartData: OcrLatencyChartData;
}

export interface IScatterDataItem {
    data?: ILatencyDataItem;
    chartData: OcrLatencyScatterData;
}

export interface IState extends ICommonState<OcrLatencyPerfMetrics> {
    dataItems: ILatencyDataItem[];
    selectedColumns?: string[];
    matchDatasetVersion?: boolean;
    includesLongTail: boolean;
    crossLanguage: boolean;
    selectedPivot: string;
    selectedMetric?: string;
    selectedType?: string;
    allColumns: TableColumn[];
    linkData?: any;
    renderData?: [string, [string, ILatencyDataItem[]][]][];
}

export function ShortenMetricKeyName(origin_metric_name: string | undefined) {
    if (origin_metric_name === undefined) {
        return "";
    } else if (origin_metric_name === "page_cnt") {
        return origin_metric_name;
    }
    const replaceMap: { [key: string]: string } = {
        detection: "detector",
        normalization: "nomalizer",
        analyzation: "analyzer",
        recognition: "recognizer",
    };
    if (origin_metric_name.indexOf("Metrics")) {
        const regex = /Metrics|_/gi;
        origin_metric_name = origin_metric_name.replaceAll(regex, "");
    } else {
        origin_metric_name = origin_metric_name.replace(
            /(?:detection|normalization|analyzation|recognition)/gi,
            (matched) => replaceMap[matched]
        );
    }

    let metric_component = origin_metric_name.split(".");
    if (metric_component.length === 1) {
        return origin_metric_name;
    } else if (metric_component.length === 2) {
        if (metric_component[0].startsWith("pod")) {
            metric_component[1] = "pod." + metric_component[1];
            return metric_component[1];
        }
        if (metric_component[0].startsWith("checkbox")) {
            return "checkboxnative." + metric_component[1];
        }
        if (metric_component[0].startsWith("table")) {
            return "table." + metric_component[1];
        }
        return metric_component.join(".");
    } else if (metric_component.length === 3) {
        return metric_component.slice(metric_component.length - 3).join(".");
    } else {
        const startIndex = metric_component.length - 4;
        // Remove "NativePerf" and "_"
        var firstStr = metric_component[startIndex].replace(
            /(?:NativePerf|_)/gi,
            ""
        );
        metric_component[startIndex] = capitalizeFirstLetter(firstStr);
        return metric_component.slice(metric_component.length - 4).join(".");
    }
}

export function MatchFieldNameIgnoreCase(
    srcFieldName: string | undefined,
    targetFieldName: string | undefined
) {
    if (srcFieldName !== undefined && targetFieldName !== undefined) {
        return srcFieldName.toLowerCase() === targetFieldName.toLowerCase();
    }
    return false;
}

export function cloneIDataItem(sourceItem: ILatencyDataItem) {
    if (sourceItem === undefined) {
        return sourceItem;
    }
    return _.cloneDeep(sourceItem);
}

export const percentileMetricPoint = [
    10, 20, 30, 40, 50, 60, 70, 80, 90, 95, 99, 99.5, 100,
];

export function percentileChartPoints(sourceItem: ILatencyDataItem) {
    if (_.isEmpty(sourceItem)) {
        return sourceItem;
    }
    const percentile_latency = sourceItem.metrics?.percentile_latency;
    if (percentile_latency) {
        percentile_latency.forEach((element) => {
            element.chartPoints.sort((a, b) => a.y - b.y);
        });

        percentile_latency.forEach((element) => {
            const chartPoints: ILineChartDataPoint[] =
                percentileMetricPoint.map(function (xpoint) {
                    let elementPoint = xpoint;
                    if (element !== undefined) {
                        elementPoint = round(
                            (xpoint * (element.chartPoints.length - 1)) / 100
                        );
                    }
                    const chartPoint = _.cloneDeep(
                        element.chartPoints[elementPoint]
                    );
                    chartPoint.x = xpoint;
                    return chartPoint;
                });

            element.chartPoints = chartPoints;
        });
    }

    return sourceItem;
}

export const languageToScript: Dictionary<string> = {
    ci: "LatinV2",
    en_US: "LatinV2",
    es_ES: "LatinV2",
    fr_FR: "LatinV2",
    de_DE: "LatinV2",
    it_IT: "LatinV2",
    pt_PT: "LatinV2",
    nl_NL: "LatinV2",
    SYMBOL: "LatinV2",
    cz_CZ: "LatinV2",
    da_DA: "LatinV2",
    hu_HU: "LatinV2",
    no_NO: "LatinV2",
    pl_PL: "LatinV2",
    sv_SV: "LatinV2",
    tr_TR: "LatinV2",
    en_US_hw: "LatinV2",
    es_ES_hw: "LatinV2",
    fr_FR_hw: "LatinV2",
    de_DE_hw: "LatinV2",
    it_IT_hw: "LatinV2",
    pt_PT_hw: "LatinV2",
    LatinUnofficial: "LatinV2",
    LatinCoverage: "LatinV2",
    single_char: "LatinV2",
    hr_HR: "LatinV2",
    ms_MY: "LatinV2",
    vi_VN: "LatinV2",
    ro_RO: "LatinV2",
    id_ID: "LatinV2",
    lt_LT: "LatinV2",
    lv_LV: "LatinV2",
    sr_RS_Latn: "LatinV2",
    sk_SK: "LatinV2",
    LatinBatch4Unofficial: "LatinV2",
    zh_CN: "CJK",
    ja_JP: "CJK",
    ko_KR: "CJK",
    zh_TW: "CJK",
    zh_CN_hw: "CJK",
    ja_JP_hw: "CJK",
    ko_KR_hw: "CJK",
    CJKCoverage: "CJK",
    ru_RU: "Cyrillic",
    CyrillicUnofficial: "Cyrillic",
    CyrillicCoverage: "Cyrillic",
    uk_UA: "Cyrillic",
    sr_RS_Cyrl: "Cyrillic",
    ar_SA: "Arabic",
    hi_IN: "Devanagari",
    DevanagariUnofficial: "Devanagari",
    Vertical_Invoice: "Vertical",
    Vertical_Invoice_DE: "Vertical",
    Vertical_Invoice_FR: "Vertical",
    Vertical_Invoice_IT: "Vertical",
    Vertical_Invoice_PT: "Vertical",
    Vertical_Invoice_NL: "Vertical",
    Vertical_Invoice_ES: "Vertical",
    Vertical_Receipt: "Vertical",
    Vertical_Receipt_EU: "Vertical",
    Vertical_Receipt_JA: "Vertical",
    Vertical_Receipt_NPD: "Vertical",
    Vertical_BizCard: "Vertical",
    Vertical_IdDocument: "Vertical",
    Vertical_Forms: "Vertical",
    Vertical_Receipt_Universal: "Vertical",
    Vertical_InsuranceCard: "Vertical",
    Latency: "Latency",
    Checkbox_Forms_Split_9: "Forms",
    checkbox_eval_csi_pactera_de: "CsiPactera",
    checkbox_eval_csi_pactera_en: "CsiPactera",
    checkbox_eval_csi_pactera_es: "CsiPactera",
    checkbox_eval_csi_pactera_fr: "CsiPactera",
    checkbox_eval_csi_pactera_it: "CsiPactera",
    checkbox_eval_csi_pactera_pt: "CsiPactera",
    el_GR: "Greek",
    th_TH: "Thai",
};

export const scriptMap = (() => {
    const scriptToLanguageMap = new Map<string, string[]>();
    Object.entries(languageToScript).forEach(([k, v]) => {
        const langs = scriptToLanguageMap.has(v)
            ? scriptToLanguageMap.get(v)!
            : [];
        if (!langs.includes(k)) {
            langs.push(k);
        }
        scriptToLanguageMap.set(v, langs);
    });
    return scriptToLanguageMap;
})();
