import * as React from "react";
import "./Header.scss";
import {
    //ContextMenu
    ContextualMenu,
    IContextualMenuItem,

    //Document
    DocumentCard,
    DocumentCardDetails,
    DocumentCardPreview,
    DocumentCardTitle,
    DocumentCardType,

    //Icon
    IconButton,
    IIconProps,

    //Message
    MessageBar,
    MessageBarType,

    //Stack
    Stack,

    //Others
    Label,
    Link,
    Modal,
    Toggle,
    getTheme,
} from "@fluentui/react";
import { Consumer, onThemeChanged } from "../Layout";
import { MsalSecurityLogoutButton } from "../SecurityLogin/SecurityButton";

export interface IHeaderLink {
    display: string;
    url: string;
}

interface IProps {
    onThemeChange: (isDarkTheme: boolean) => void;
    isDarkTheme: boolean;
    version: string;
}

interface IState {
    showPrivacy: boolean;
    visibleContextualMenu: boolean;
    showReleaseUpdate: boolean;
}

const theme = getTheme();

const ICON_STYLES = {
    root: {
        color: theme.palette.neutralPrimary,
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const CANCEL_ICON: IIconProps = { iconName: "Cancel" };

const MENU_ITEMS: IContextualMenuItem[] = [
    {
        key: "data",
        text: "Data",
        iconProps: { iconName: "PageData" },
        onClick: () => {
            window.open("/data", "_self");
        },
    },
    {
        key: "evaluation",
        text: "Evaluation",
        iconProps: { iconName: "DiagnosticDataBarTooltip" },
        onClick: () => {
            window.open("/eval", "_self");
        },
    },

    {
        key: "powerbi",
        text: "GPU Usage Report",
        iconProps: { iconName: "PowerBILogo" },
        onClick: () => {
            window.open("/powerbi", "_self");
        },
    },
    {
        key: "jsonToHtml",
        text: "HtmlConvertor",
        iconProps: { iconName: "FileHTML" },
        onClick: () => {
            window.open("/htmlConvertor", "_self");
        },
    },
];

const RELEASE_CONTENT = [
    {
        releaseTitle:
            "Update the display of AWS, GA3.0, GA3.1 for the Trend page",
        releaseActivity: "Mar 28, 2025",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/Update_the_display_of_Trend_page.png",
    },
    {
        releaseTitle: "Update the latency metrics key according to new data",
        releaseActivity: "Mar 28, 2025",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Update_the_latency_metrics_key_according_to_new_data.png",
    },
    {
        releaseTitle:
            "Add filter group to filter the category, modality, task tags for Data viewer",
        releaseActivity: "Feb 28, 2025",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Add_filter_group_to_filter_the_category_modality_task_tags_for_Data_viewer.png",
    },
    {
        releaseTitle: "Fix S360 issue of update the eval-proc to net 8",
        releaseActivity: "Feb 28, 2025",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add to switch show the error rate diff in percentage for OCR workspace",
        releaseActivity: "Jan 10, 2025",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Add_to_switch_show_the_error_rate_diff_in_percentage_for_OCR_workspace.png",
    },
    {
        releaseTitle:
            "Refactor metrics data structure for OCR Large Model metrics",
        releaseActivity: "Jan 10, 2025",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Refactor_metrics_data_structure_for_OCR_Large_Model_metrics.png",
    },
    {
        releaseTitle: "Fix the disappear icons",
        releaseActivity: "Jan 10, 2025",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add Overview page (chart/table view) for the Large Model Metrics",
        releaseActivity: "Dec 13, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Add_Overview_page_for_the_Large_Model_Metrics.png",
    },
    {
        releaseTitle: "Add By Image page for the Large Model Metrics",
        releaseActivity: "Dec 13, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Add_By_Image_page_for_the_Large_Model_Metrics.png",
    },
    {
        releaseTitle:
            "Add image visualization page for the Large Model Metrics",
        releaseActivity: "Dec 13, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Add_image_visualization_page_for_the_Large_Model_Metrics.png",
    },
    {
        releaseTitle: "Update the pop-up dialog column for POD",
        releaseActivity: "Dec 13, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Update_the_pop-up_dialog_column_for_POD.png",
    },
    {
        releaseTitle: "Refactor log naming rule and add debug trace log",
        releaseActivity: "Dec 13, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Add to support the json V2 format for pdf files",
        releaseActivity: "Nov 8, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix the text overlap issue for OCR-Entity metrics, multiple files be downloaded after clicking the OCR-Entity metrics export button,  the pop-up dialog dismiss issue for the Latency metrics",
        releaseActivity: "Oct 25, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add tips for download feature while no cross language table for OCR-Entity metrics",
        releaseActivity: "Oct 11, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Add_tips_for_download_feature_while_no_cross_language_table_for_OCR-Entity_metrics.png",
    },
    {
        releaseTitle:
            "Change to load the image after clicking on it for Data viewer",
        releaseActivity: "Oct 11, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix the security issue for Release test workspace, the first image of current dataset always be loaded even filter to other images for Data viewer, the export table data button should be disabled if no data on current table, the export table data button not work for the pop-up dialog, the download button color issue under dark theme",
        releaseActivity: "Oct 11, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Add the copy table feature for table list control",
        releaseActivity: "Sep 14, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Add_the_copy_table_feature_for_table_list_control.png",
    },
    {
        releaseTitle:
            "Add the handle for data incompletely downloading for OCR-Highlight page",
        releaseActivity: "Sep 14, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Add_the_handle_for_data_incompletely_downloading_for_OCR-Highlight_page.png",
    },
    {
        releaseTitle: "Add the copy table feature for overview table control",
        releaseActivity: "Aug 30, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Add_the_copy_table_feature_for_overview_table_control.png",
    },
    {
        releaseTitle:
            "Change to list the different datasets for OCR - highlight metrics if the records dataset does not match",
        releaseActivity: "Aug 30, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Change_to_list_the_different_datasets_for_OCR_highlight_metrics.png",
    },
    {
        releaseTitle:
            "Improve UI of table list column and align issue for OCR workspace",
        releaseActivity: "Aug 16, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Improve_UI_of_table_list_column_and_align_issue_for_OCR_workspace.png",
    },
    {
        releaseTitle: "Improve the chart view with the small value number",
        releaseActivity: "Aug 16, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Improve_the_chart_view_with_the_small_value_number.png",
    },
    {
        releaseTitle:
            "Improve the value number display for chart view and the table view for OCR workspaces",
        releaseActivity: "Jul 19, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Improve_the_value_number_display_for_chart_view_and_the_table_view_for_OCR_workspaces.png",
    },
    {
        releaseTitle: "Fix the paging issue for Figure record list page",
        releaseActivity: "Jul 19, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Fix_the_paging_issue_for_Figure_record_list_page.png",
    },
    {
        releaseTitle:
            "Calculate and display the percentage for Logical Role Overview page of POD workspace",
        releaseActivity: "Jul 5, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Calculate_and_display_the_percentage_for_Logical_Role_Overview_page_of_POD_workspace.png",
    },
    {
        releaseTitle: "Add imageid and dataset info on the image detail page",
        releaseActivity: "Jun 21, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Addimageidanddatasetinfoontheimagedetailpage.png",
    },
    {
        releaseTitle:
            "Update to resolve the concurrent request fail issue for OCR highlight page",
        releaseActivity: "Jun 21, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Add to support clear the record cache",
        releaseActivity: "Jun 21, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix issue of cannot log in again immediately after log out, some links need to add the authentication in DataViewer, the empty page issue when the first compare record data is NaN for POD and Table, crash issue on logical Role data for POD",
        releaseActivity: "Jun 21, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Pop up window display on the double-clicking position on the page",
        releaseActivity: "Jun 07, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Popupwindowdisplayonthedoubleclickingpositiononthepage.png",
    },
    {
        releaseTitle:
            "Update for the Security issues and add clear cache button",
        releaseActivity: "Jun 07, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix issue for the two different image id be compared under logical role By Image page of POD",
        releaseActivity: "Jun 07, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix the issue of the data resumed after change 'Choose Metrics Level' options for OCR-Entity metrics, the coordinate axis invisible issue under the dark theme and View as Chart/Table icon disappear issue for Release Test",
        releaseActivity: "May 24, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Refactor the merge data function for Latency metrics pages",
        releaseActivity: "May 10, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Refresh the image list after sort the column on By Image pages",
        releaseActivity: "May 10, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix the issue of no tips while no data shown for Table workspace, slow response issue while expanding the Latin/CJK/Syrillic under WER section on OCR-Highlight page",
        releaseActivity: "May 10, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add the 'match dataset version' feature for POD and Table workspace",
        releaseActivity: "Apr 26, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddMatchdatasetVersion.png",
    },
    {
        releaseTitle:
            "Add Latency Metrics - Overview/ MetricAnalysis/ ComponentLatency/ By Image tab pages for KVP workspace",
        releaseActivity: "Apr 26, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/ChangeColumn.png",
    },
    {
        releaseTitle:
            "Change to not show the count table if all the records don't have this data for KVP workspace",
        releaseActivity: "Apr 26, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/NoShowCount.png",
    },
    {
        releaseTitle:
            "Update the calculationg on 'percentileChartPoints' for all the latency metrics page",
        releaseActivity: "Apr 26, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Update UI format for non-drill down tables",
        releaseActivity: "Apr 26, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add Latency Metrics - Overview/ MetricAnalysis/ ComponentLatency/ By Image tab pages for KVP workspace",
        releaseActivity: "Apr 12, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddKVPLatencyMetrics.png",
    },
    {
        releaseTitle:
            "Different UI style for the images that have already been seen by double clicking or opening from the OCR Toy link",
        releaseActivity: "Apr 12, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/DifferentUIStyle4Images.png",
    },
    {
        releaseTitle: "Change point color for the OCR Benchmark Trend page",
        releaseActivity: "Apr 12, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/ChangeBenchmarkPointColor.png",
    },
    {
        releaseTitle:
            "Fix the issue of dismiss pop up dialog while switching between tabs for POD workspace, no data tips display issue for OCR-Highlight page",
        releaseActivity: "Apr 12, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add drill down feature for Overview page - cross dataset table, expand the 'relationAgnosticOrder' to new table and change column data for Reading Order cross dataset table for POD workspace",
        releaseActivity: "Mar 29, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddDrillDownFeature4OverviewPage.png",
    },
    {
        releaseTitle: "Add GoogleDAI data for OCR Benchmark Trend page",
        releaseActivity: "Mar 29, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddGoogleDAIData4OCR.png",
    },
    {
        releaseTitle:
            "Fix the drill down and data issues for CustomForm workspace, switch the record issue on the image visualization page, crash issue for Barcode workspace, pie chart display issue for POD workspace",
        releaseActivity: "Mar 29, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add the drill down feature and adjust column width for Cross Datasate table data on the POD - Logical Role Overview page",
        releaseActivity: "Mar 15, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/LogicalRoleOverview.png",
    },
    {
        releaseTitle: "Update UI for the already seen images for By Image page",
        releaseActivity: "Mar 15, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/UpdateUI4AlreadySeenImages.png",
    },
    {
        releaseTitle: "Add scroll bar on the OCR Highlight page",
        releaseActivity: "Mar 15, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddScrollBarOnOCRHighlight.png",
    },
    {
        releaseTitle:
            "Update pie chart UI and add no data tips for CustomForm Error Analysis page",
        releaseActivity: "Mar 15, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/UpdatePieChartUI.png",
    },
    {
        releaseTitle:
            "Update the display rule of 'WER Regression / Improvement Top 20' table for OCR - General & Detection Metrics page",
        releaseActivity: "Mar 15, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/UpdateDisplayRule.png",
    },
    {
        releaseTitle:
            "Fix the blue line thickness issue for category sections, tips display issue for the Latency - Pie chart",
        releaseActivity: "Mar 15, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add the cross table data for 'Logical Role Overview' page and loading image feature for 'Logical Role By Image' page of POD workspace",
        releaseActivity: "Mar 01, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddLogicalRoleOverview.png",
    },
    {
        releaseTitle:
            "Update data of 'matched_pred' and 'matched_gt' and image visualization for Figure workspace",
        releaseActivity: "Mar 01, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/UpdateDataOfMatchedPred.png",
    },
    {
        releaseTitle: "Add the 'Error Analysis' page for CustomForm workspace",
        releaseActivity: "Mar 01, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddErrorAnalysis.png",
    },
    {
        releaseTitle:
            "Update the metrics name and display fields in pie chart for MATH_OCR workspace",
        releaseActivity: "Mar 01, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/UpdateNameDisplayFieldsInPiechart4MATH_OCR.png",
    },
    {
        releaseTitle:
            "Fix the issue of crash issue for Figure workspace, pie chart tips issue for Latency data",
        releaseActivity: "Mar 01, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Update displayed columns and add the download option for each experiment of By Image page for POD and Table workspace",
        releaseActivity: "Feb 02, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/UpdateDisplayedColumns.png",
    },
    {
        releaseTitle:
            "Add the Logical Overview/ Logical By Image page for POD workspace",
        releaseActivity: "Feb 02, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddLogicalOverview.png",
    },
    {
        releaseTitle:
            "Support docx/ pptx/ xlsx file in image visualization and compatible with new data format for Doc Classifier workspace",
        releaseActivity: "Feb 02, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/SupportDocxPptxXlsx.png",
    },
    {
        releaseTitle:
            "Fix issue for Benchmark Trend display issue, handle the 'null' value for CustomForm, display issue on small screen",
        releaseActivity: "Feb 02, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Create the 'Query Field' workspace and add the record list/ overview(table)/ overview(chart)/ detail metrics pages",
        releaseActivity: "Jan 19, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/CreateQueryField.png",
    },
    {
        releaseTitle:
            "Add Overview and By Image page for HDSA data for POD workspace",
        releaseActivity: "Jan 19, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddOverviewandImage4HDSA.png",
    },
    {
        releaseTitle:
            "Change to keep 'overall' row on bottom while sorting by columns for Barcode workspace",
        releaseActivity: "Jan 19, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/Change2keepOverall.png",
    },
    {
        releaseTitle: "Remove the 'move to top' button",
        releaseActivity: "Jan 19, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Integrate the code of Query Field with CustomForm code",
        releaseActivity: "Jan 19, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix issue on full screen container display issue, cross table display issue for POD workspace, remove the left navigation bar for data pipeline page, the dataset name invisible under dark theme, no data prompt issue for Barcode workspace",
        releaseActivity: "Jan 19, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add confidence score table and chart for Invoice workspace",
        releaseActivity: "Jan 5, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddConfidenceScore.png",
    },
    {
        releaseTitle: "Update the Overview page layout",
        releaseActivity: "Jan 5, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/UpdateOverview.png",
    },
    {
        releaseTitle: "Support non-image file type in OCR Toy link",
        releaseActivity: "Jan 5, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix the issue of the horizontal scroll bar location issue for table list",
        releaseActivity: "Jan 5, 2024",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Create the `Query Field` workspace in backend",
        releaseActivity: "Dec 22, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Refactor the overview page for OCR Barcode Metrics/ POD/ Table/ Figure workspace",
        releaseActivity: "Dec 22, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Remove the swagger for the security policy reason",
        releaseActivity: "Dec 22, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix the issue of overview statistical for CustomForm workspace, the loading always display on the Record list page issue, cannot load data issue for ReleaseTest",
        releaseActivity: "Dec 22, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add image visualization page and update for the data change for Doc Classifier workspace",
        releaseActivity: "Dec 8, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/Add_image_visualization_page.png",
    },
    {
        releaseTitle:
            "Refactor code for Image visualization page and support the pdf file",
        releaseActivity: "Dec 8, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Refactor selectPivot and OCR EntityMetrics and DetectionMetrics overview page code",
        releaseActivity: "Dec 8, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Remove the API Doc context menu for policy reason",
        releaseActivity: "Dec 8, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix the issue of the menu text issue of fold/expand button, the data issue in the cross dataset metrics table for POD",
        releaseActivity: "Dec 8, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Change UI for the record list page - multiple selection feature",
        releaseActivity: "Nov 24, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/multipleSelectionChangeUI.png",
    },
    {
        releaseTitle:
            "Refactor code for the Latency Metrics pages for all workspaces and overview page code for OCR workspace",
        releaseActivity: "Nov 24, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Update 'json to html' service",
        releaseActivity: "Nov 24, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix the issue of pop up window while double clicking on records, the 'NaN' data for Latency Metrics, line not reach to 100% when comparing records for OCR_Figure workspace",
        releaseActivity: "Nov 24, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Update the loading mask only cover on the record list section",
        releaseActivity: "Oct 27, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/RefactorLoading.png",
    },
    {
        releaseTitle:
            "Fix the issue of line chart disappear after switching from MetricAnalysis page for OCR_Figure workspace, the coordinate axis invisible issue under dark theme for Latency MetricAnalysis page",
        releaseActivity: "Oct 27, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add the reading order data into the cross dataset metrics table and add more metrics in MetricAnalysis chart for OCR_POD workspace",
        releaseActivity: "Oct 13, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddReadingOrderData.png",
    },
    {
        releaseTitle:
            "Update the time range for GA builds in OCR Benchmark Trend page and the point color issue under dark theme for Vertical Trend page",
        releaseActivity: "Oct 13, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/UpdateTimeRange4GA.png",
    },
    {
        releaseTitle:
            "Refactor code for “loading” feature for all pages/ the filter code for 'shortenMetricsName' for Latency Metrics- Pie chart",
        releaseActivity: "Oct 13, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/RefactorCode4LoadingFeature.png",
    },
    {
        releaseTitle:
            "Update the 'Export All' feature for all workspaces and 'Fold All' feature for MATH_OCR",
        releaseActivity: "Oct 13, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/UpdateExportAllFeature.png",
    },
    {
        releaseTitle:
            "Integrate the 'Json to Html' service and update the contextualMenu to 'GPU Usage Report'",
        releaseActivity: "Oct 13, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/IntegrateJson2HtmlServiceUpdateContextualMenu2GPUUsageReport.png",
    },
    {
        releaseTitle:
            "Fix issue for cross dataset metrics calculation for OCR_Figure workspace, the empty section issue for Table workspace, the no data page prompt for OCR workspace",
        releaseActivity: "Oct 13, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add the dataset dropdown control for cross dataset table and change image path to 'GT_VIS' for POD workspace",
        releaseActivity: "Sep 15, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddDatasetDropdownCtrl.png",
    },
    {
        releaseTitle: "Add the cross dataset table for OCR_Figure workspace",
        releaseActivity: "Sep 15, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddCrossDataset4OCRFigure.png",
    },
    {
        releaseTitle: "Enable the previous/ next feature for the OCR Toy",
        releaseActivity: "Sep 15, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/EnablePreviousNextFeature.png",
    },
    {
        releaseTitle: "Embedded power BI control for AML GPU Report",
        releaseActivity: "Sep 15, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/EmbeddedPowerBI.png",
    },
    {
        releaseTitle:
            "Add the drill down feature for the Table General metrics-Table detection tab",
        releaseActivity: "Sep 1, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addDrillDownOnTable.png",
    },
    {
        releaseTitle:
            "Add setting feature to choose MetricAnalysis data and add drill down feature for OCR_Figure workspace",
        releaseActivity: "Sep 1, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddSettingFeature.png",
    },
    {
        releaseTitle:
            "Refactor the left navigation bar code and update the metrics name and support key for POD",
        releaseActivity: "Sep 1, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix the issue of empty pie chart for Checkbox, digit display error for pie chart for OCR_Figure, loading issue for POD and OCR_Figure, crash issue for POD",
        releaseActivity: "Sep 1, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add to show 'EER' data and link to record if clicking the point on chart for OCR Benchmark Trend page",
        releaseActivity: "Aug 18, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/showEER.png",
    },
    {
        releaseTitle: "Update the left navigation bar into groups on UI",
        releaseActivity: "Aug 18, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateLeftNav.png",
    },
    {
        releaseTitle:
            "Create the Latency Metrics pages (Overview, MetricsAnalysis, ComponentLatency, By Image) for OCR_Figure workspace",
        releaseActivity: "Aug 18, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/createLatencyMetrics.png",
    },
    {
        releaseTitle:
            "Split to several sections and add the drill down feature for Overview page and adjust the column order for POD and Table workspace",
        releaseActivity: "Aug 18, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/SplitToSeveralSections.png",
    },
    {
        releaseTitle:
            "Fix the issue of exact and extra different values, +0 issue in overviewtable control, update semver version, crash issue for OCR-Entity Metrics",
        releaseActivity: "Aug 18, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Update the Bench mark trend page and filter code for OCR workspace",
        releaseActivity: "Aug 04, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/UpdateBenchmarkTrend.png",
    },
    {
        releaseTitle:
            "Add the image visualization page for General Metrics-By Image page and handle the multiple iou values for OCR_Figure workspace",
        releaseActivity: "Aug 04, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddImageVisualization.png",
    },
    {
        releaseTitle:
            "Update to show the percentage in chart view for 'f1/precision/recall/average similarity score' and fix the value and shown field and crash issues for POD workspace",
        releaseActivity: "Aug 04, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/UpdatePodCrash.png",
    },
    {
        releaseTitle: "Update to self-adapt the height of column in chart",
        releaseActivity: "Aug 04, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/UpdateSelfAdapt.png",
    },
    {
        releaseTitle:
            "Fix the crash issue for CustomForm workspace, trend line for 'firstName' and 'lastName' for ExperimentalPrebuilt workspace",
        releaseActivity: "Aug 04, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Add Benchmark Trend page",
        releaseActivity: "Jul 21, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addBenchMark.png",
    },
    {
        releaseTitle:
            "Create Doc_Classifier workspace and create the Overview/ By Image page for it",
        releaseActivity: "Jul 21, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/CreateDoc_Classifier.png",
    },
    {
        releaseTitle:
            "Create OCR_Figure workspace and create the General metrics pages for it",
        releaseActivity: "Jul 21, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/CreateOCR_Figure.png",
    },
    {
        releaseTitle:
            "Update the chart on 'MetricsAnalysis' page for OCR-Latency Metrics",
        releaseActivity: "Jul 21, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/UpdateMetricsAnalysis.png",
    },
    {
        releaseTitle:
            "Convert the 'f1/ precision/ recall/ average similarity score' value to percentage with 2 decimal places for all tables for POD-General metrics",
        releaseActivity: "Jul 21, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/ConvertDecimal.png",
    },
    {
        releaseTitle:
            "Fix the swagger page cannot be loaded issue, the setting resume issue for all Latency metrics page, the expand icon issue for ReleaseTest workspace, the wrong image path issue for Table workspace, the average accuracy value calculation for CustomForm workspace",
        releaseActivity: "Jul 21, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Create the Experimental Prebuilt workspace and add the Record list/ Overview Metrics/ By Field Metrics/ Trend pages",
        releaseActivity: "Jul 7, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/createExpPrebuilt.png",
    },
    {
        releaseTitle:
            "Add the double clicking feature to open the original image in new tab and add 'average similarity score' data in the chart for POD workspace",
        releaseActivity: "Jul 7, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addReadingOrder.png",
    },
    {
        releaseTitle:
            "Fix the crash issue and double request issue for POD workspace, NaN data issue for Table and POD workspace, vertical data does not match with Latency chart issue and wrongly show the no data tips issue for OCR workspace",
        releaseActivity: "Jul 7, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Update Pie chart issue for MATH_OCR/ Table/ POD workspaces",
        releaseActivity: "Jun 25, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/fixPieChart4Show.png",
    },
    {
        releaseTitle:
            "Add the column of 'average similarity score' and 'edit_distance' on the Overview and By Image for POD workspace",
        releaseActivity: "Jun 25, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addColumnsForPOD.png",
    },
    {
        releaseTitle: "Refactor the viewType code to unify the management",
        releaseActivity: "Jun 25, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix the wrong data issue dataset display issue for OCR workspace, the setting resume issue and column change issue for MATH_OCR workspace, image load issue for Checkbox workspace, the n/a data issue for CustomForm workspace, compatible with the history data for CustomDocReleaseTest workspace",
        releaseActivity: "Jun 25, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add 'MetricAnalysis' and 'ComponentLatency' page and update to calculate the overall data for Table and POD Latency Metrics",
        releaseActivity: "Jun 9, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/calculateLatencyMetrics.png",
    },
    {
        releaseTitle: "Update and integrate the features to the OCRToy",
        releaseActivity: "Jun 9, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateInvestigateOcrToy.png",
    },
    {
        releaseTitle: "Refactor the setting panel code to unify the management",
        releaseActivity: "Jun 9, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix the crash issue for OCR/MATH/Barcode, refresh issue on the scrolled position in image visualization, cannot switch the language for OCR Highlight page Latency chart, no data tips shown while drilling down for OCR General Metrics, the loading always shown issue for Barcode",
        releaseActivity: "Jun 9, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add P100 data on the Highlight page in Latency section for OCR workspace",
        releaseActivity: "May 26, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addP100data.png",
    },
    {
        releaseTitle:
            "Add download image feature for Latency Metrics->By Image page for Table and POD workspaces",
        releaseActivity: "May 26, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addDownload4POD.png",
    },
    {
        releaseTitle:
            "Add counts columns and overview table for region_grouping and logic role page for POD workspace",
        releaseActivity: "May 26, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddCountsColumns.png",
    },
    {
        releaseTitle:
            "Refactor the setting panel and viewType code to unify the management",
        releaseActivity: "May 26, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix the legend lost issue for not data dataset for OCR workspace, the inner expand button sync with external expand button issue for ReleaseTest, the setting resumed issue for MATH_OCR workspace, the crash issue for  OCR-Latency metrics page and MATH_OCR image page",
        releaseActivity: "May 26, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add table view for General metrics of Table and POD workspaces",
        releaseActivity: "May 12, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addTableView4GeneralMetrics.png",
    },
    {
        releaseTitle:
            "Enable the setting panel and fix the legend lost issue for Latency metrics - chart view of Table and POD workspaces",
        releaseActivity: "May 12, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/enableSettingPanelAndFixIssue.png",
    },
    {
        releaseTitle: "Add the Latency metrics page for MATH_OCR workspace",
        releaseActivity: "May 12, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addLatency4Math.png",
    },
    {
        releaseTitle:
            "Render the url string as anchor link for CustomDocReleaseTest workspace",
        releaseActivity: "May 12, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/renderURLasAnchor.png",
    },
    {
        releaseTitle:
            "Normalize Metrics key name for Table and POD Latency metrics page",
        releaseActivity: "Apr 28, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/NormalizeMetricsKey.png",
    },
    {
        releaseTitle:
            "Update the sorting feature for all columns on all tags for Table and POD workspaces",
        releaseActivity: "Apr 28, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/UpdateSortingFeature.png",
    },
    {
        releaseTitle: "Update the image path for new dataset for MATH_OCR",
        releaseActivity: "Apr 28, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix the crash issue while clicking the note icon for Barcode, data load issue for Latency metrics page of Table and POD workspace, empty 'Entities' issue for OCR-Entity metrics, list item wrong count issue for ReleaseTest",
        releaseActivity: "Apr 28, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Update the page structure and add the drill down feature for Latency metrics for POD and Table workspace",
        releaseActivity: "Apr 14, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updatePODandTableLatency.png",
    },
    {
        releaseTitle:
            "Fix the issue of white block displayed for OCR_Barcode, update NaN data and setting column reset issue for Table workspace,  the dataset dropdown list cannot be changed for Latency metrics page, white block issue for OCR_Barcode",
        releaseActivity: "Apr 14, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Create Latency metrics page for Table and OCR_POD workspaces",
        releaseActivity: "Mar 31, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addLatency4PODandTable.png",
    },
    {
        releaseTitle:
            "Disable the copy icon,  change the 'Correct' different value color and update the data while comparing for OCR_Barcode workspace",
        releaseActivity: "Mar 31, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/DisableCopyIcon.png",
    },
    {
        releaseTitle:
            "Split the download button to Image download button and label file download button",
        releaseActivity: "Mar 31, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/SplitDownloadBtn.png",
    },
    {
        releaseTitle:
            "Fix the issue of refresh the scrolled position while switching dataset, render formula issue for MATH_OCR, regression issue of match the color of records which in header and chart, the different value precision issue for Barcode",
        releaseActivity: "Mar 31, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Create Performance Metrics - Overview/ By Image/ image visualization page for OCR_Barcode workspace",
        releaseActivity: "Mar 17, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/CreatePerformanceMetrics.png",
    },
    {
        releaseTitle:
            "Update formula transformation feature to support more Italian symbol for MATH_OCR workspace",
        releaseActivity: "Mar 17, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/UpdateFormulaTransformation.png",
    },
    {
        releaseTitle:
            "Fix the issue of wrong record legend color after unmarking the record and move up/down, crash issue for OCR-Latency metrics, always loading issue if no compared record",
        releaseActivity: "Mar 17, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Create General Metrics - image visualization page for OCR_Barcode workspace",
        releaseActivity: "Mar 03, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/imgVisualForBarcode.png",
    },
    {
        releaseTitle:
            "Add the render of formula transformation on image visualization for MATH_OCR workspace",
        releaseActivity: "Mar 03, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/imgVisualForFormula.png",
    },
    {
        releaseTitle:
            "Add sorting feature for Reliability page for ReleaseTest workspace",
        releaseActivity: "Mar 03, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/improveSort4Reliability.png",
    },
    {
        releaseTitle: "Improve the UI for 'Fold All' and 'Export All' button",
        releaseActivity: "Mar 03, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/improveUI4Fold.png",
    },
    {
        releaseTitle: "Improve the linkage on header and chart",
        releaseActivity: "Mar 03, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/improveLinkage.png",
    },
    {
        releaseTitle:
            "Save access data in log file and parse them to get the statistic data",
        releaseActivity: "Mar 03, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix issue on data loading while marking or unmarking the records",
        releaseActivity: "Mar 03, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add download button to download current image with label file on image visualization page",
        releaseActivity: "Feb 17, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addDownLoadButton.png",
    },
    {
        releaseTitle:
            "Create the General - Overview and By Image page for OCR_Barcode workspace",
        releaseActivity: "Feb 17, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/createBarCode.png",
    },
    {
        releaseTitle:
            "Add Symbol Metrics page and data level/ change column option and sorting feature for MATH_OCR workspace",
        releaseActivity: "Feb 17, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addSymbolMetrics.png",
    },
    {
        releaseTitle:
            "Enable the setting panel for changing the columns for OCR-Detection Metrics and Checkbox workspace",
        releaseActivity: "Feb 17, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/enableSettingOnDetection&Checkbox.png",
    },
    {
        releaseTitle:
            "Improve the performance for not refresh and download the data again while unchecking or moving the selected record",
        releaseActivity: "Feb 17, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Improve the loading performance on OCR-Latency page while turning off the cross-script option/ switching dark theme/ opening setting panel",
        releaseActivity: "Feb 17, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Update the eval-proc packages",
        releaseActivity: "Feb 17, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix the issue of cross-dataset table cannot be loaded if only have invoice data for OCR-Entity Metrics, the baseline URL issue for Release Test",
        releaseActivity: "Feb 17, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add 'ClmStatus' 'ClmResult' data, group 'insurance card' type and change to use the 'entity error count' data for OCR-Entity Metrics",
        releaseActivity: "Feb 03, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addClm.png",
    },
    {
        releaseTitle:
            "Add 'SingleNumberRestrictedOther' 'SingleNumberRestrictedTable' and remove 'SingleNumberRestricted' for OCR-Highlights Metrics",
        releaseActivity: "Feb 03, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addSingle.png",
    },
    {
        releaseTitle:
            "Improve the loading performance for ReleaseTest-SanityTest/Vertical Santity Test/ Form Recognizer 30 Sanity Test pages",
        releaseActivity: "Feb 03, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Upgrade eval-proc to Net 6 LTS",
        releaseActivity: "Feb 03, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix the white border issue under dark theme, the color change issue on image visualization page, changing column width issue for ReleaseTest-SanityTest",
        releaseActivity: "Feb 03, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add export feature on Overview page for OCR-Detection, OCR-WordAlign, Checkbox, Invoice, MATH_OCR, Receipt, Business_Card, ID_Card, Industry_Prebuilt, Health_Doc workspaces",
        releaseActivity: "Jan 13, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/detectionExportFunc.png",
    },
    {
        releaseTitle:
            "Add 'ErrorTagLink' on By field page for Receipt, Business_Card, ID_Card, Industry_Prebuilt, Health_Doc workspaces",
        releaseActivity: "Jan 13, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addErrorTagLink.png",
    },
    {
        releaseTitle:
            "Improve the image visualization UI on the Enable checkbox",
        releaseActivity: "Jan 13, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/improveImgVisual.png",
    },
    {
        releaseTitle: "Update the calculation for OCR-Language metrics",
        releaseActivity: "Jan 13, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Update the nodejs packages",
        releaseActivity: "Jan 13, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix the interaction issue of selecting record and changing order feature, the coordinate system issue and Pie chart display issue for OCR-Latency, the image load issue and data display issue for MATH_OCR, data load issue for Health_Doc",
        releaseActivity: "Jan 13, 2023",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Get and show the user alias on VDI insight portal",
        releaseActivity: "Dec 23, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/GetAnd ShowTheUserAliasOnVDIinsightPortal.png",
    },
    {
        releaseTitle: "Add controller and create OCR_Barcode workspace",
        releaseActivity: "Dec 23, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/AddControllerAndCreateOCR_BarcodeWorkspace.png",
    },
    {
        releaseTitle: `Improve the trigger logic for "Keep filter options" toggle`,
        releaseActivity: "Dec 23, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Unit test for utils common functions",
        releaseActivity: "Dec 23, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: `Fix the attribute not shown issue for Data pipeline, the "Match Dataset Version" toggle issue for Invoice&KVP, refresh page issue while clicking "Fold All", data invisible issue for OCR-Latency, the UI issue under dark theme, the fold icon issue for  ReleaseTest, the letter truncated issue for folded dataset`,
        releaseActivity: "Dec 23, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Update OcrMS data NaN issue and data list on By Image page for OCR-Latency Metrics",
        releaseActivity: "Dec 09, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateOcrMSData.png",
    },
    {
        releaseTitle:
            "Add columns on the by field page for Reiceipt/ Business_Card/ ID_Card/ Industry_Prebuilt/ Health_Doc workspaces",
        releaseActivity: "Dec 09, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addColumnsFieldPage.png",
    },
    {
        releaseTitle:
            "Add export feature on the Overview page for OCR-Hightlight/Latency Metrics",
        releaseActivity: "Dec 09, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addExportFeature.png",
    },
    {
        releaseTitle:
            "Update the OCR-Language Metrics page according to new requirements",
        releaseActivity: "Dec 09, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateOCR-Language.png",
    },
    {
        releaseTitle:
            "Hide the datasets which word count is 0 for OCR-WordAlign Metrics",
        releaseActivity: "Dec 09, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/hideDatasets.png",
    },
    {
        releaseTitle:
            "Improve the refresh behavior for deleting or changing the records",
        releaseActivity: "Dec 09, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/improveRefreshBehavior.png",
    },
    {
        releaseTitle: "Improve the point color of chart under dark theme",
        releaseActivity: "Dec 09, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/improvePointColor.png",
    },
    {
        releaseTitle: "Investigate and fix the build failed issue",
        releaseActivity: "Dec 09, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Save the selection of 'Dataset' and 'Category' for By Image and By Textline page for OCR & KVP workspace",
        releaseActivity: "Nov 25, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/saveTheSelection.png",
    },
    {
        releaseTitle:
            "Add export feature for all pages and add 'entity prediction' column under OCR-Entity Metrics",
        releaseActivity: "Nov 25, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/exportEntityExcel.png",
    },
    {
        releaseTitle:
            "Update the color of URL font/ close button / drop down list under dark theme and align the expand icon",
        releaseActivity: "Nov 25, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/dropDownListUI.png",
    },
    {
        releaseTitle:
            "Update the sorting feature for CustomDocReleaseTest and POD workspaces",
        releaseActivity: "Nov 25, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateSortFeater.png",
    },
    {
        releaseTitle:
            "Change to calculate and show the union data while comparing (Table & POD workspace)",
        releaseActivity: "Nov 25, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Update to get data directly on the non-General/non-Overview page",
        releaseActivity: "Nov 25, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix the crash issue and wrong filter issue and pop up window dismiss issue for OCR-Entity metrics, the data wrongly filter issue for Invoice workspace, combine table on top issue for KVP workspace, Update chart text 'Value' and its location,  the setting icon disable for CustomForm workspace",
        releaseActivity: "Nov 25, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Create Health_Doc workspace and add the Trend/ Overview/ By Field pages",
        releaseActivity: "Nov 11, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/health_doc.png",
    },
    {
        releaseTitle:
            "Add to show the 'commonName' field data for KVP workspace",
        releaseActivity: "Nov 11, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/commonNameFieldDataForKVP.png",
    },
    {
        releaseTitle:
            "Optimizing the image visualization on image displaying/ header box/ highlight box",
        releaseActivity: "Nov 11, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/optimizingTheImageVisualization.png",
    },
    {
        releaseTitle: "Update the color of Link in table list",
        releaseActivity: "Nov 11, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateTheColorOfLinkInTableList.png",
    },
    {
        releaseTitle: "Update UI for Release Test - Reliability Test page",
        releaseActivity: "Nov 11, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/updateUIForReleaseTestReliabilityTestPage.png",
    },
    {
        releaseTitle: "Update UI for left navigation bar under the dark theme",
        releaseActivity: "Nov 11, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/updateUIForLeftNavigationBarUnderTheDarkTheme.png",
    },
    {
        releaseTitle:
            "Improve the getting data logic on non-General/non-Overview page",
        releaseActivity: "Nov 11, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Refactor the page status management to ReactRedux (Health workspace)",
        releaseActivity: "Nov 11, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Change to calculate and show the union data while comparing (Table workspace)",
        releaseActivity: "Nov 11, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Set the library version which the code depends on",
        releaseActivity: "Nov 11, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Create Prediction Metrics/ By Prediction Line page for OCR-Entity Metrics",
        releaseActivity: "Oct 28, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/createPrediction.png",
    },
    {
        releaseTitle:
            "Create Industry_Prebuilt workspace and add Trend/ Overview/ By Field pages",
        releaseActivity: "Oct 28, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/createIndustry.png",
    },
    {
        releaseTitle:
            "Update the calculation and columns for OCR-Highlight page",
        releaseActivity: "Oct 28, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateCerculation.png",
    },
    {
        releaseTitle:
            "Update the UI format under dark theme for header column, link, setting icon, OCR-Entity pop up window",
        releaseActivity: "Oct 28, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateUIFormatForPop.png",
    },
    {
        releaseTitle: "Update UI for ReleaseTest - Sanity test page",
        releaseActivity: "Oct 28, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateReleaseTest.png",
    },
    {
        releaseTitle: "Change ocr toy url prefix from 'westus2' to 'westus3'",
        releaseActivity: "Oct 28, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/change2to3.png",
    },
    {
        releaseTitle: "Speed up the loading time for OCR-Latency Metrics page",
        releaseActivity: "Oct 28, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/speedUpLoad.png",
    },
    {
        releaseTitle:
            "Fix the loading issue for ReleaseTest-AccuracyTest page, the image truncate issue for Checkbox, pop up tips shape for Taipei vertical workspaces, crash issue for MATH_OCR, optimizing big data record slow loading issue, drop down list empty and unselecting issue for OCR-Entity Vertical page",
        releaseActivity: "Oct 28, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Enable dark theme for all pages",
        releaseActivity: "Oct 14, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/EnableDarkTheme.png",
    },
    {
        releaseTitle:
            "Add Baseline Test page for CustomDocReleaseTest workspace",
        releaseActivity: "Oct 14, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateData4MathOcr.png",
    },
    {
        releaseTitle:
            "Update the data according to new data format and fix the image cannot load issue for MATH_OCR",
        releaseActivity: "Oct 14, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addReleaseTest4CustomDocReleaseTest.png",
    },
    {
        releaseTitle:
            "Reorder the tabs, remove the By Entity page, add the double click tips for OCR-Entity metrics",
        releaseActivity: "Oct 14, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateDisplayAlignment.png",
    },
    {
        releaseTitle:
            "Update image visualization on highlight the clicked textline and fix the bbox malposed issue",
        releaseActivity: "Oct 14, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateImgVisualizationHighlight.png",
    },
    {
        releaseTitle:
            "Update the display alignment issue and data for OCR-Highlight and OCR-Entity metrics",
        releaseActivity: "Oct 14, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/reorderTags.png",
    },
    {
        releaseTitle:
            "Fix the 'Latency' table data issue and 'View as Chart' button disappear issue on OCR-Highlight page, crash issue and wrong entity options issue for OCR-Entity, clear the search content while switching dataset for KVP, the previous button flashed issue and long tags issue for image visualization, the 'Select All' button in table filter cannot cancel issue",
        releaseActivity: "Oct 14, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Setup the Unit Test frame",
        releaseActivity: "Oct 14, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Create Math_OCR workspace and add Overview/ By Image page for it",
        releaseActivity: "Sep 16, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addImgPage4Ocr.png",
    },
    {
        releaseTitle: "Update the calculation for OCR-Highlight metrics",
        releaseActivity: "Sep 16, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/createAvgOverviewTable.png",
    },
    {
        releaseTitle:
            "Update image visualization page for OCR-WordAlign metrics",
        releaseActivity: "Sep 16, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateImageVisualization4WA.png",
    },
    {
        releaseTitle:
            "Fix issue of search bar for Tablelist control, empty drop-down list and vertical diff value for OCR-Entity, npm package issue",
        releaseActivity: "Sep 16, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Create Overview page  By Image page  Customized Image visualization page for OCR Word Align Metrics",
        releaseActivity: "Sep 2, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/CreateOverviewPageByImagePageCustomizedImageVisualizationPage.png",
    },
    {
        releaseTitle: "Check and sort the options for drop down list",
        releaseActivity: "Sep 2, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/CheckAndSortTheOptionsForDropDownlist.png",
    },
    {
        releaseTitle:
            "Remove decimal place for TrueFalseXXX and XXXCount fields on TableList control",
        releaseActivity: "Sep 2, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/RemoveDecimalPlaceForTrueFalseXXXAndXXXCountFields.png",
    },
    {
        releaseTitle: "Update image visualization page",
        releaseActivity: "Sep 2, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/UpdateImageVisualizationPage.png",
    },
    {
        releaseTitle: "Update UI for Release test - Overview page",
        releaseActivity: "Sep 2, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/UpdateUIForReleaseTest-OverviewPage.png",
    },
    {
        releaseTitle:
            "Add Entity type drop down list on OCR-Entity Vertical cross language table",
        releaseActivity: "Sep 2, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/AddEntityTypeDropDownListOnOCR-EntityVerticalCrossLanguageTable.png",
    },
    {
        releaseTitle:
            "Fix issue of drill down in OCR-Latency Metrics, empty latency chart on OCR-Highlight Metrics, data issue in KVP,  Analysis toggle for Release test-Accuracy Test",
        releaseActivity: "Sep 2, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Change the image visualization page UI to new design with next/ previous image feature",
        releaseActivity: "Aug 19, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/changeImageVisualizationUI.png",
    },
    {
        releaseTitle:
            "Add comparing feature for 'ref' and 'hyp' content and font options for image visualization page",
        releaseActivity: "Aug 19, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addComparingFeatureForRefAndHyp.png",
    },
    {
        releaseTitle:
            "Add summary table and sort feature, update diff value color/precision for CustomForm workspace",
        releaseActivity: "Aug 19, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/addSummaryTableAndSortFeatureUpdateDiffValueColorprecisionForCustomFormWorkspace.png",
    },
    {
        releaseTitle:
            "Update the TableList control sorting feature and fix issue while combining with the filter and search feature",
        releaseActivity: "Aug 19, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/updateTheTableListControlSortingFeatureAndFixIssueWhileCombiningWithTheFilterAndSearchFeature.png",
    },
    {
        releaseTitle: "Add analysis table for OCR-General metrics chart view",
        releaseActivity: "Aug 19, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/addAnalysisTableForOCRGeneralMetricsChartView.png",
    },
    {
        releaseTitle:
            "Add cross language table for OCR-Entity Metrics-Overview-Vertical page",
        releaseActivity: "Aug 19, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/addCrossLanguageTableForOCREntityMetricsOverviewVerticalPage.png",
    },
    {
        releaseTitle:
            "Fix issue on keep filter options feature, crash issue for OCR-Entity Metrics, value error and record name display issue for Checkbox workspace",
        releaseActivity: "Aug 19, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add toggle to keep/not keep the filter options and fix mismatch and sorting issue on TableList control",
        releaseActivity: "Aug 05, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/keepFilterToggle.png",
    },
    {
        releaseTitle:
            "Check and added the loading icon for all pages while loading data",
        releaseActivity: "Aug 05, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/checkLoading.png",
    },
    {
        releaseTitle:
            "Update to uniform behavior on 'Fold/Expand All' icons on summary analysis data table",
        releaseActivity: "Aug 05, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateUniformBehavior.png",
    },
    {
        releaseTitle:
            "Create expand table for OCR-Entity-Overview page cross language Entity Metrics table",
        releaseActivity: "Aug 05, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/createExpandTable.png",
    },
    {
        releaseTitle:
            "Add the toggle of 'Show entities under documental scenario only' for OCR-Entity-Error distribution page",
        releaseActivity: "Aug 05, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addToggleOnDIstributionPage.png",
    },
    {
        releaseTitle:
            "Add sorting feature for Receipt, Business_Card, ID_Card - Overview page",
        releaseActivity: "Aug 05, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addSortingFeature.png",
    },
    {
        releaseTitle:
            "Fix always loading issue while no data to be loaded, non-default position issue on expand data table, wrong type after drill down and back to page, the 'vertical_' data should be list under the 'Vertical' section, remove the 'Ground Truth' title for Checkbox-Image visualization page",
        releaseActivity: "Aug 05, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add dropdown field to select subtype for POD and Table workspace",
        releaseActivity: "July 22, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/AddDropdownFieldToSelectSubtypeForPODandTableWorkspace.png",
    },
    {
        releaseTitle:
            "Add data on the Overview and By Document page for KVP workspace",
        releaseActivity: "July 22, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/AddDataOntheOverviewAndByDocumentPageForKVPworkspace.png",
    },
    {
        releaseTitle:
            "Update according to new requirements for Checkbox workspace",
        releaseActivity: "July 22, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/UpdateAccordingToNewRequirementsForCheckboxWorkspace.png",
    },
    {
        releaseTitle:
            "Update table list title filter to filter multiple tags and calculate matched languages for OCR-Entity Metrics",
        releaseActivity: "July 22, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/UpdateFilterToFilterMultipleTagsAndCalculateMatchedLanguagesForOCR-EntityMetrics.png",
    },
    {
        releaseTitle:
            "Update the data schema to V2 for Receipt, Business_Card, ID_Card workspace",
        releaseActivity: "July 22, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix the issue of show and select entity option on the image view for OCR-Entity Metrics, multiple entity tags issue for OCR-Entity-By EntityLine page",
        releaseActivity: "July 22, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Rearrange the data display order and fix order bug for OCR_POD workspace",
        releaseActivity: "July 08, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/RearrangeDataDisplay.png",
    },
    {
        releaseTitle: "Add Error distribution page for OCR-Entity Metrics",
        releaseActivity: "July 08, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddErrorDistribution.png",
    },
    {
        releaseTitle:
            "Support image visualization for OCR-Entity Metrics- By Entity Line page",
        releaseActivity: "July 08, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddEntityLineVis.png",
    },
    {
        releaseTitle: "Integrate the OCRToy to VDI portal",
        releaseActivity: "July 08, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addOcrToy.png",
    },
    {
        releaseTitle:
            "Update the data with special column setting link for Receipt, Business_Card, ID_Card workspaces",
        releaseActivity: "July 08, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/UpdateDataFromSpecial.png",
    },
    {
        releaseTitle:
            "Speed up the data loading function to improve the performance",
        releaseActivity: "July 08, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix the issue of 'Algorithm' dropdown list for Receipt, Business_Card, ID_Card workspaces, the issue of adding favorite while searching",
        releaseActivity: "July 08, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Support exact search on the record list page for all workspaces",
        releaseActivity: "June 24, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/SupportExactSearchOnTheRecordListPage.png",
    },
    {
        releaseTitle: "Support to export data of tables on OCR-General Metrics",
        releaseActivity: "June 24, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Support_to_export_data_of_tables_on_OCR-General_Metrics.png",
    },
    {
        releaseTitle:
            "Add chart view and update table view on Overview Metrics page for KVP workspace",
        releaseActivity: "June 24, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Add_chart_view_and_update_table_view.png",
    },
    {
        releaseTitle:
            "Change to new table view data format on Overview Metrics page for Checkbox workspace",
        releaseActivity: "June 24, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Change_to_new_table_view_data_format.png",
    },
    {
        releaseTitle:
            "Update simple automatic analysis data for OCR-General metrics",
        releaseActivity: "June 24, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Update_simple_automatic_analysis_data.png",
    },
    {
        releaseTitle: "Fix crash issue and LINK format issue",
        releaseActivity: "June 24, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add chart view and fix crash issue for OCR-Highlight Metrics",
        releaseActivity: "June 10, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/highlightChart.png",
    },
    {
        releaseTitle:
            "Support to list the data with special column setting for Receipt, Business_Card, ID_Card workspaces",
        releaseActivity: "June 10, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/columnSet.png",
    },
    {
        releaseTitle: "Support special format search",
        releaseActivity: "June 10, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/specialSearch.png",
    },
    {
        releaseTitle:
            "Fix issue on 'Fold/Expand All' menu for OCR-Entity metrics, 'Select All' filter option issue, remove 'unofficial' option for OCR-Entity Metrics",
        releaseActivity: "June 10, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add change order feature with selected records for all workspaces",
        releaseActivity: "May 27, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Add_change_order_feature_with_selected_records.png",
    },
    {
        releaseTitle:
            "Add change order feature with header columns for all workspaces",
        releaseActivity: "May 27, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Add_change_order_feature_with_header_columns.png",
    },
    {
        releaseTitle:
            "Add edit tag feature from header column for all workspaces",
        releaseActivity: "May 27, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Add_edit_tag_feature_from_header_colum.png",
    },
    {
        releaseTitle: `Add "Fold/Expand All" feature for Overview page of OCR, Invoice, KVP workspaces`,
        releaseActivity: "May 27, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Add_Fold_Expand_All_feature_for_Overview_page.png",
    },
    {
        releaseTitle:
            "Add simple automatic analysis for OCR-General and Detection Metrics",
        releaseActivity: "May 27, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/Add_simple_automatic_analysis.png",
    },
    {
        releaseTitle:
            "Reduce the loaded data and change the OCRToy link for POD workspace, reduce the shown data for OCR-Entity Metrics",
        releaseActivity: "May 27, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Improve the loading record list page for Receipt, Business_Card, ID_Card workspaces",
        releaseActivity: "May 27, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Fix issue on crash issue and changing dataset behavior for OCR-Detection Metrics, improve multiple selection feature on record list page, report label error issue for OCR-Entity Metrics, change column width issue for ReleaseTest-SanityTest",
        releaseActivity: "May 27, 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Support multiple selection on record list page for all workspaces",
        releaseActivity: "May 13 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/multipleSelection.png",
    },
    {
        releaseTitle:
            "Support paging feature on record list page for all workspaces",
        releaseActivity: "May 13 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/pagingFeature.png",
    },
    {
        releaseTitle:
            "Allow user to save the column setting for all workspaces",
        releaseActivity: "May 13 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/saveColumnSetting.png",
    },
    {
        releaseTitle:
            "Update x axis tick interval of the trend chart for Receipt, Business_Card, ID_Card workspaces",
        releaseActivity: "May 13 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/xAxisTickIntervalOfTheTrendChart.png",
    },
    {
        releaseTitle:
            "Fix the issue of wrong PredictionFieldName options for Receipt workspace, setting for CustomForm workspace",
        releaseActivity: "May 13 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Update to support multiple search feature for all workspaces",
        releaseActivity: "Apr 29 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/multipleSearch.png",
    },
    {
        releaseTitle:
            "Create Overview metrics/ By Image /Image visualization pages for OCR-Detection Metrics",
        releaseActivity: "Apr 29 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/detectionView.png",
    },
    {
        releaseTitle:
            "Add POD workspace and create Record List/ General Metrics/ By Image Metrics pages",
        releaseActivity: "Apr 29 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/podWorkspace.png",
    },
    {
        releaseTitle: "Update UI for OCR-Highlight metrics",
        releaseActivity: "Apr 29 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/highlightUI.png",
    },
    {
        releaseTitle:
            "Update record list data for Receipt, Business_Card, ID_Card",
        releaseActivity: "Apr 29 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateUI.png",
    },
    {
        releaseTitle:
            "Fix issue on option list for OCR-Entity metrics, crash issue for OCR-Latency and OCR-Entity metrics, remove horizontal scroll bar for all Record list page, report label error issue",
        releaseActivity: "Apr 29 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },

    {
        releaseTitle:
            "Add 'record id' column on record list page for all workspaces",
        releaseActivity: "Apr 15 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/AddRecordIdColumnOnRecordListPageForAllWorkspaces.png",
    },
    {
        releaseTitle: "Update the tab title for all workspaces",
        releaseActivity: "Apr 15 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/UpdateTheTabTitleForAllWorkspaces.png",
    },
    {
        releaseTitle:
            "Add error tagging link on By Field page for Receipt, Business_Card, ID_Card workspace",
        releaseActivity: "Apr 15 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/AddErrorTaggingLinkOnByFieldPageForReceiptBusinessCardIDCardworkspace.png",
    },
    {
        releaseTitle: "Add dataset version for Table workspace",
        releaseActivity: "Apr 15 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddDatasetVersionForTableWorkspace.png",
    },
    {
        releaseTitle:
            "Fix issue on Exp link pop up issue and empty UI, ID column width for ReleaseTest workspace, refresh issue and data align issue for OCR-Entity Metrics, crash issue for OCR-Latency Metrics",
        releaseActivity: "Apr 15 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Change OcrToy url prefix for Invoice, KVP, Customform, Table workspace",
        releaseActivity: "Apr 1 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/ChangeOcrToyUrlPrefixForInvoiceKVPcustomformTableWorkspace.png",
    },
    {
        releaseTitle:
            "Update the Table and dataset category for OCR entity metrics",
        releaseActivity: "Apr 1 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/UpdateTheTableAndDatasetCategoryForOCRentityMetrics.png",
    },
    {
        releaseTitle: "Change name to Highlight page in OCR workspace",
        releaseActivity: "Apr 1 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/ChangeNameToHighlightPageInOCRworkspace.png",
    },
    {
        releaseTitle:
            "Fix crash issue in Table workspace and slicer in Telemetry workspace",
        releaseActivity: "Apr 1 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add slicer feature for Vertical_Telemetry Report Detail Page",
        releaseActivity: "Mar 18 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/AddSlicerFeatureForVertical_TelemetryReportDetailpage.png",
    },
    {
        releaseTitle:
            'Remove the "Show Left-Only/Right-Only lines" options from the filter icon for non-OCR workspaces',
        releaseActivity: "Mar 04 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/removeFilter.png",
    },
    {
        releaseTitle: "Add Highlight page (Table view) for OCR workspace",
        releaseActivity: "Mar 04 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/highlightPage.png",
    },
    {
        releaseTitle:
            "Add Report Detail page (without slicer section) for Vertical_Telemetry workspace",
        releaseActivity: "Mar 04 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/telemetryReport.png",
    },
    {
        releaseTitle:
            'Add search by “Algorithm” and "StepRunId“ feature on Record list page',
        releaseActivity: "Feb 18 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addSearch.png",
    },
    {
        releaseTitle: "Add tag and legend on header info",
        releaseActivity: "Feb 18 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddTag.png",
    },
    {
        releaseTitle:
            'Change "ExpRunId" to "StepRunId" on Receipt, Business Card, ID Card Trend page',
        releaseActivity: "Feb 18 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/expRunId.png",
    },
    {
        releaseTitle:
            "Add “Create Base Dataset” function on Telemetry record list page",
        releaseActivity: "Feb 18 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/createDataset.png",
    },
    {
        releaseTitle:
            "Fix issue of search option of CustomForm, default column width of release test record list page, blank tag,change Entity default setting　",
        releaseActivity: "Feb 18 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Update filter icon to hide for 1 record and to show for multiple records.",
        releaseActivity: "Feb 18 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateFilter.png",
    },

    {
        releaseTitle: 'Add "back to top" control on each page',
        releaseActivity: "Jan 21 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Add-back-to-top-control-on-each-page.png",
    },
    {
        releaseTitle:
            'Add "Vertical_Telemetry" workspace and create "Vertical Telemetry Records" page under it',
        releaseActivity: "Jan 21 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Add-Vertical_Telemetry-workspace-and-create-Vertical-Telemetry-Records-page-under-it.png",
    },
    {
        releaseTitle: "Fix drill down issue for secondary sub entities data",
        releaseActivity: "Jan 21 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },

    {
        releaseTitle:
            "Support search by Model, Runtime, BuildSource, TestType, Tag, ID on Record list page for all workspaces",
        releaseActivity: "Jan 7 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/support-search-by-Model-Runtime-BuildSource-TestType-Tag-ID.png",
    },
    {
        releaseTitle:
            "Fix BuildSource, ExpId, macro data issue for Receipt, Business_Card, ID_Card workspace",
        releaseActivity: "Jan 7 2022",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Add report label error feature on image visualization",
        releaseActivity: "Dec 24 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/add-report-label-error-feature-on-image-visualization.png",
    },
    {
        releaseTitle:
            "Integrate the removing duplicate compareType item on Overview page for Receipt, Business_Card, ID_Card workspace",
        releaseActivity: "Dec 24 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Integrate-removing-duplicate-compareTyp-item.png",
    },
    {
        releaseTitle:
            "Update Trend chart for Receipt, Business_Card, ID_Card workspace",
        releaseActivity: "Dec 24 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Update-Trend-chart-for-Receip-Business_Card-ID_Card-workspace.png",
    },
    {
        releaseTitle:
            "Add the select all option for the table title filter for all workspace",
        releaseActivity: "Dec 09 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/120901.png",
    },
    {
        releaseTitle:
            "Add compareType filter on the Overview page for Receipt, Business_Card, ID_Card workspace",
        releaseActivity: "Dec 09 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/120902.png",
    },
    {
        releaseTitle:
            "Add tips and update the compared different value color for Receipt, Business_Card, ID_Card workspace",
        releaseActivity: "Dec 09 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/120903.png",
    },
    {
        releaseTitle:
            "Fix data crash issues, 'VisualLink' data issue, edit tag issue, add 'ViewType' in url for default link",
        releaseActivity: "Dec 09 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/drilldown.png",
    },
    {
        releaseTitle:
            "Add drill down feature from Overview page to By Image page for OCR workspace",
        releaseActivity: "Nov 26 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Update the 'Receipt, BusinessCard, Id' workspace: Change data source file to split by fieldName; Remove the different for Index fields; Change to show 'Create time' data",
        releaseActivity: "Nov 26 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/changeData.png",
    },
    {
        releaseTitle:
            "Update to refresh the data list after clicking the X button in search bar",
        releaseActivity: "Nov 26 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Merge and order the table title filter list",
        releaseActivity: "Nov 26 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/filterList.png",
    },
    {
        releaseTitle: "Fix the data crash issues",
        releaseActivity: "Nov 26 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Add bar chart for Invoice workspace",
        releaseActivity: "Nov 12 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/AddBarChartForInvoiceWorkspace.png",
    },
    {
        releaseTitle: "Revise the subgroup display code for Entity Metrics",
        releaseActivity: "Nov 12 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/ReviseTheSubgroupDisplayCodeForEntityMetrics.png",
    },
    {
        releaseTitle:
            "Update the FieldName-CompareType pairs, DocId, CompareType, GroundTruthIndex data for Receipt, BusinessCard, ID workspaces",
        releaseActivity: "Nov 12 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/UpdateTheFieldNameCompareTypePairsDocIdCompareType.png",
    },
    {
        releaseTitle:
            "Update the header items for for Receipt, BusinessCard, ID workspaces",
        releaseActivity: "Nov 12 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/UpdateTheHeaderItemsForTaiBeiWorkspaces.png",
    },
    {
        releaseTitle: "Update dataset item UI for Invoice and KVP workspace",
        releaseActivity: "Nov 12 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/UpdateDatasetItemUIForInvoiceAndKVPWorkspace.png",
    },
    {
        releaseTitle:
            "Fix issue on setting icon of componentLatency page, NaN value in CustomForm workspace",
        releaseActivity: "Nov 12 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Update the field values and differences to the same type.",
        releaseActivity: "Oct 29 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/sameType.png",
    },
    {
        releaseTitle:
            "Update table column and table title filter on Record list, Overview, By Field pages for Receipt, BusinessCard, ID workspaces.",
        releaseActivity: "Oct 29 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/b1zCard.png",
    },
    {
        releaseTitle:
            "Create Trend page with filter feature for Receipt, Business_Card, ID_Card workspaces",
        releaseActivity: "Oct 15 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/CreateTrendPageWithFilterforReceiptBusiness_CardID_Card.png",
    },
    {
        releaseTitle:
            "Update drill down feature for Receipt, Business_Card, ID_Card workspaces",
        releaseActivity: "Oct 15 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/UpdateDrillDownForReceiptBusiness_CardID_Card.png",
    },
    {
        releaseTitle:
            "Customize the 'Show identical/ different lines' menu for Invoice workspace",
        releaseActivity: "Oct 15 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/CustomizeTheShowIdenticalOrDifferentLinesMenuForInvoice.png",
    },
    {
        releaseTitle:
            "Fix bug for crash issue and 'No url of image' error on Latency Metrics, left nav panel width",
        releaseActivity: "Oct 15 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add Receipt/ Business Card/ ID workspace and update according to feedbacks",
        releaseActivity: "Sep 17 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/AddReceiptBusinessCardIDworkspaceAndApdateAccordingToFeedbacks.png",
    },
    {
        releaseTitle: "Add ocrtoy URL for Table workspace",
        releaseActivity: "Sep 17 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addocrtoy URLforTable workspace.png",
    },
    {
        releaseTitle:
            "Add average data, update the search by tag feature and other feedbacks for CustomForm workspace",
        releaseActivity: "Sep 17 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/AddaveragedataforCustomFormworkspace.png",
    },
    {
        releaseTitle:
            "Update meta info and filter feature and row height for Invoice workspace",
        releaseActivity: "Sep 17 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc:
            "/ReleaseNote/Updatemetainfoandfilterfeatureandrowheight.png",
    },
    {
        releaseTitle:
            'Fix issue on General Metrics dataset options, "reportonly"&"tabkey" parameter in URL, uncaught error for CustomForm, remove duplicate drop down list, duplicate favorite items',
        releaseActivity: "Sep 17 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Add API and script on backend to save label and filter records by days",
        releaseActivity: "Sep 17 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Update Latency MetricsAnalysis chart y axis range",
        releaseActivity: "Sep 3 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateLatencyChartYrange.png",
    },
    {
        releaseTitle:
            "Update table title filter feature with search and order conditions",
        releaseActivity: "Sep 3 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateTitleFilter.png",
    },
    {
        releaseTitle:
            "Update table presentation and filter option for Invoice workspace",
        releaseActivity: "Sep 3 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updatePresentationForInvoice.png",
    },
    {
        releaseTitle: "Update search feature for Table workspace",
        releaseActivity: "Sep 3 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateSearchForTable.png",
    },
    {
        releaseTitle:
            "Update Latency scatter chart point color/chart count, diff value presentation",
        releaseActivity: "Sep 3 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateLatencyScatter.png",
    },
    {
        releaseTitle: `Fix issue on crash data, setting icon location, "Match Dataset version" toggle, empty search content after select dataset`,
        releaseActivity: "Sep 3 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Update tag UI to new style and support upper case letters.",
        releaseActivity: "Aug 19 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateTag.png",
    },
    {
        releaseTitle: "Add “By Image Metrics” page for Table workspace.",
        releaseActivity: "Aug 19 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/entityList.png",
    },
    {
        releaseTitle: "Update URL format with “tabKey” request parameter.",
        releaseActivity: "Aug 19 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/improveURL.png",
    },
    {
        releaseTitle: "Add decoding process on URL.",
        releaseActivity: "Aug 19 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Remove “Exp link” column if no link records.",
        releaseActivity: "Aug 19 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Support double click to open image in new tab for Latency metrics.",
        releaseActivity: "Aug 19 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/doubleClick.png",
    },
    {
        releaseTitle:
            "Fix issue for favorite records list, chart number presentation, KVP table data, Latency scatter/pie chart, crash by Table data.",
        releaseActivity: "Aug 19 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Add security check for search by tag feature.",
        releaseActivity: "Aug 19 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Add language filter for Latency scatter chart.",
        releaseActivity: "Aug 05 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/scatterChart.png",
    },
    {
        releaseTitle:
            "Update Latency line/scatter/pie chart switch feature when no data.",
        releaseActivity: "Aug 05 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateLatency.png",
    },
    {
        releaseTitle:
            "Create new document page and update the drill down feature for KVP workspace.",
        releaseActivity: "Aug 05 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addKvp.png",
    },
    {
        releaseTitle:
            "Fix issue for Latency pie chart and line chart, crash on data, search on whole page.",
        releaseActivity: "Aug 05 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/scatterPoint.png",
    },
    {
        releaseTitle: "Latency Metrics – Scatter chart.",
        releaseActivity: "July 27 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/scatterPoint.png",
    },
    {
        releaseTitle: "Latency Metrics – Pie chart.",
        releaseActivity: "July 27 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/pieChart.png",
    },
    {
        releaseTitle: "Update tips UI format.",
        releaseActivity: "July 27 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/tip.png",
    },
    {
        releaseTitle:
            "Update color, thickness, coordinates system for Latency metrics line chart.",
        releaseActivity: "July 27 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/lineChartColor.png",
    },
    {
        releaseTitle: "Add filter function for all table list.",
        releaseActivity: "July 27 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/filterSearch.png",
    },
    {
        releaseTitle:
            "Update search function to search all columns for all table list.",
        releaseActivity: "July 27 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/nagtive.png",
    },
    {
        releaseTitle:
            "Fix issue for Latency metrics line chart, General metrics bar chart, Language metrics, Invoice drill down feature, favorite presentation, data crash issue.",
        releaseActivity: "July 27 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Add Line chart chart for Latency Metrics.",
        releaseActivity: "July 09 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/linechart.png",
    },
    {
        releaseTitle: "x/y range customize for Latency Metrics Line chart.",
        releaseActivity: "July 09 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/linechart_setting.png",
    },
    {
        releaseTitle:
            "Update table column min width for Latency overview page.",
        releaseActivity: "July 09 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateColumnWidth.png",
    },
    {
        releaseTitle: "Update the logo image for web site.",
        releaseActivity: "July 09 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateLogo.png",
    },
    {
        releaseTitle: "Update the icon for Evaluation workspace.",
        releaseActivity: "July 09 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addColorIcon.png",
    },
    {
        releaseTitle: "Update the bar chart color to new style.",
        releaseActivity: "July 09 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/changeBarColor.png",
    },
    {
        releaseTitle: "Update the data presentation for Invoice.",
        releaseActivity: "July 09 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateStyleWithCompare.png",
    },
    {
        releaseTitle:
            "Fix issue for Entity setting, Checkbox image list,  Scroll bar for Latency page, General view as chart option, Loading icon for Load all records button, Invoice data overlap issue.",
        releaseActivity: "July 09 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Update the data for Entity Cross language metrics.",
        releaseActivity: "July 09 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateEntityDataset.png",
    },

    {
        releaseTitle: "Add drill down feature for Invoice.",
        releaseActivity: "July 09 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addDrillDown.png",
    },
    {
        releaseTitle:
            "Fix issue for Checkbox data crash, Latency chart legend, Invoice search by tag.",
        releaseActivity: "July 09 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Manually clear the redis cache from backend.",
        releaseActivity: "July 09 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Add cdn for ocrdataset.",
        releaseActivity: "July 09 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addCDN.png",
    },

    {
        releaseTitle: "Table title freeze.",
        releaseActivity: "June 24 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/freezeTitle.png",
    },
    {
        releaseTitle: "Add x,y axis label for all charts.",
        releaseActivity: "June 24 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/addAxis.png",
    },
    {
        releaseTitle: "Hover to show full item name.",
        releaseActivity: "June 24 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/hoverItem.png",
    },
    {
        releaseTitle:
            "Fix bug for extra blank, horizontal scroll bar, table fold, page header string, chart legend, Checkbox image list.",
        releaseActivity: "June 24 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle:
            "Update Latency Metrics overview bar chart by metrics instead of total only.",
        releaseActivity: "June 24 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/latencyOver.png",
    },
    {
        releaseTitle:
            "Update Latency Metrics overview compared data to relative diff value.",
        releaseActivity: "June 24 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/latencyCompared.png",
    },
    {
        releaseTitle: "Add cross category metrics for Latency/ By Image page.",
        releaseActivity: "June 24 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/updateCross.png",
    },
    {
        releaseTitle:
            "Update Entity Metrics data to compare identical language records.",
        releaseActivity: "June 24 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/entityLang.png",
    },
    {
        releaseTitle: "Fix duplicated records issue for search by tag feature.",
        releaseActivity: "June 24 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/FixEntitysetting.png",
    },
    {
        releaseTitle: "Improve performance on blob cache.",
        releaseActivity: "June 24 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/improvePerf.png",
    },
    {
        releaseTitle:
            "Update blob controller to use redirect url instead of sdk.",
        releaseActivity: "June 24 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/settingPanel.png",
    },
    {
        releaseTitle: "Integrate the setting panel.",
        releaseActivity: "June 11 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/settingPanel.png",
    },
    {
        releaseTitle: "Change chart control.",
        releaseActivity: "June 11 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/chartControl.png",
    },

    {
        releaseTitle: "Add OcrCheckbox image view.",
        releaseActivity: "June 11 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/checkboxImg.png",
    },
    {
        releaseTitle: "Add search by tag feature.",
        releaseActivity: "June 11 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/searchTag.png",
    },
    {
        releaseTitle:
            "Update OCRTable and OCRCheckbox to show metrics by dataset.",
        releaseActivity: "June 11 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/VDIlayout.png",
    },
    {
        releaseTitle: "Move the ExpLink into detail page.",
        releaseActivity: "June 11 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/VDIlayout.png",
    },
    {
        releaseTitle: "Update the Checkbox Detection data.",
        releaseActivity: "June 11 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/VDIlayout.png",
    },
    {
        releaseTitle: "Improve performance on redis cache.",
        releaseActivity: "June 11 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/VDIlayout.png",
    },

    {
        releaseTitle: "Update UI framework to new style.",
        releaseActivity: "April 09 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/VDIlayout.png",
    },
    {
        releaseTitle:
            "Adjust the hierarchical relationship in Language Expansion.",
        releaseActivity: "April 09 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/crossLan.png",
    },
    {
        releaseTitle:
            "Add more statistical data for Entity Metrics (cross-language entity metrics, children entity metrics, Doc/NonDoc categories entity metrics).",
        releaseActivity: "April 09 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/crossLan.png",
    },
    {
        releaseTitle: "Add more log tracking on backend.",
        releaseActivity: "April 09 2021",
        releaseUrl: "/eval/ocr",
    },
    {
        releaseTitle:
            "Add/Delete the tags for each record by tapping the tag icon.",
        releaseActivity: "March 26 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/release1.png",
    },
    {
        releaseTitle: "Enable accuracy test in release test.",
        releaseActivity: "March 28 2021",
        releaseUrl: "/eval/ocr",
        previewImageSrc: "/ReleaseNote/releaseaccuracy.png",
    },
];

export class Header extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this._onToggleChange = this._onToggleChange.bind(this);

        this.state = {
            showPrivacy: true,
            visibleContextualMenu: false,
            showReleaseUpdate: false,
        };
    }

    public render(): JSX.Element {
        const { showPrivacy, visibleContextualMenu } = this.state;

        return (
            <Stack verticalAlign="start">
                <Consumer>
                    {(value) => {
                        return (
                            <Stack
                                className={"headerStack"}
                                horizontal
                                verticalAlign="center"
                                tokens={{ childrenGap: 20 }}
                                styles={{
                                    root: {
                                        backgroundColor: value
                                            ? theme.palette.neutralDark
                                            : theme.semanticColors
                                                  .primaryButtonBackground,
                                    },
                                }}
                            >
                                <Stack
                                    horizontal
                                    verticalAlign="center"
                                    className={"websiteTitle"}
                                >
                                    <IconButton
                                        className={"iconButton"}
                                        iconProps={{
                                            iconName: "Waffle",
                                            styles: {
                                                root: {
                                                    color: theme.palette.white,
                                                    fontSize: "25px",
                                                    marginLeft: "10px",
                                                },
                                            },
                                        }}
                                        onClick={(evt) => {
                                            this._headerTarget =
                                                evt.target as HTMLElement;
                                            this.setState({
                                                visibleContextualMenu: true,
                                            });
                                        }}
                                    />
                                    <ContextualMenu
                                        className={"ContextualMenuStyle"}
                                        items={MENU_ITEMS}
                                        gapSpace={10}
                                        hidden={!visibleContextualMenu}
                                        target={this._headerTarget}
                                        onItemClick={() => {
                                            this.setState({
                                                visibleContextualMenu: true,
                                            });
                                        }}
                                        onDismiss={() =>
                                            this.setState({
                                                visibleContextualMenu: false,
                                            })
                                        }
                                    />

                                    <a
                                        href="/"
                                        title="Insight"
                                        aria-label="Insight"
                                        style={{ marginTop: "5px" }}
                                    >
                                        <img
                                            src="/logo_insight_new.png"
                                            alt="Insight"
                                            className={"microsoftLogo"}
                                        />
                                    </a>

                                    <h6>
                                        <Link
                                            onClick={() =>
                                                this.setState({
                                                    showReleaseUpdate: true,
                                                })
                                            }
                                            style={{
                                                textDecoration: "none",
                                                color: "white",
                                            }}
                                        >
                                            {this.props.version}
                                        </Link>
                                    </h6>
                                    {this.openVersionLog()}
                                </Stack>

                                <Stack
                                    style={{ marginLeft: "auto" }}
                                    horizontal
                                    tokens={{ childrenGap: 10 }}
                                    verticalAlign="baseline"
                                >
                                    <Label
                                        style={{
                                            color: theme.palette.white,
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        Dark Theme:
                                    </Label>
                                    <Toggle
                                        onText="ON"
                                        offText="OFF"
                                        checked={
                                            this.props.isDarkTheme ?? false
                                        }
                                        onChange={this._onToggleChange}
                                    />

                                    <MsalSecurityLogoutButton />
                                </Stack>
                            </Stack>
                        );
                    }}
                </Consumer>

                {showPrivacy && (
                    <MessageBar
                        onDismiss={this._closePrivacy}
                        dismissButtonAriaLabel="Close"
                        messageBarType={MessageBarType.info}
                    >
                        Microsoft Global Data Protection Notice for Employees,
                        External Staff, and Candidates.
                        <Link
                            href="https://msdpn.azurewebsites.net/default?LID=62"
                            target="_blank"
                            styles={{
                                root: {
                                    color: "#75b6e7",
                                },
                            }}
                        >
                            Learn more.
                        </Link>
                    </MessageBar>
                )}
            </Stack>
        );
    }

    private _headerTarget: HTMLElement | null | undefined;

    private _onToggleChange() {
        onThemeChanged(this.props.isDarkTheme);
        this.props.onThemeChange(this.props.isDarkTheme);
    }

    private _closePrivacy = (): void => {
        this.setState({ showPrivacy: false });
    };

    private openVersionLog(): JSX.Element {
        let { showReleaseUpdate } = this.state;
        return (
            <Modal
                isOpen={showReleaseUpdate}
                onDismiss={() =>
                    this.setState({
                        showReleaseUpdate: false,
                    })
                }
                isBlocking={false}
                containerClassName={"contentStyles"}
            >
                <div
                    className={"contentHeader"}
                    style={{
                        borderTop: `4px solid $ {theme.palette.themePrimary}`,
                        color: theme.palette.neutralPrimary,
                    }}
                >
                    <Label style={{ fontSize: "20px" }}>Version Log</Label>
                    <IconButton
                        className={"iconButton"}
                        styles={ICON_STYLES}
                        iconProps={CANCEL_ICON}
                        ariaLabel="Close popup modal"
                        onClick={() =>
                            this.setState({
                                showReleaseUpdate: false,
                            })
                        }
                    />
                </div>
                <div className={"contentBody"}>
                    <Stack tokens={{ childrenGap: 10 }}>
                        {RELEASE_CONTENT.map((release, idx) => {
                            return (
                                <div
                                    className="ms-Viewport"
                                    key={`modal_${idx}`}
                                >
                                    <DocumentCard
                                        type={DocumentCardType.compact}
                                        onClickHref={release.releaseUrl}
                                    >
                                        <DocumentCardPreview
                                            previewImages={[
                                                {
                                                    previewImageSrc:
                                                        release.previewImageSrc,
                                                    width: 144,
                                                },
                                            ]}
                                        />
                                        <DocumentCardDetails>
                                            <DocumentCardTitle
                                                className="release__title"
                                                title={release.releaseTitle}
                                            />
                                            <div className="release__content">
                                                <Label>
                                                    Release Date:
                                                    {release.releaseActivity}
                                                </Label>
                                            </div>
                                        </DocumentCardDetails>
                                    </DocumentCard>
                                </div>
                            );
                        })}
                    </Stack>
                </div>
            </Modal>
        );
    }
}
